import React from "react";
import {Edit} from "react-admin";

import InsideForm from "./InsideForm";

const CustomTitle = (props) => `Zgoda ${props.record.name}`;

export default (props) => {
  return (
    <Edit {...props} title={<CustomTitle />}>
      <InsideForm {...props} />
    </Edit>
  );
};
