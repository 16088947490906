import React, {useEffect} from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {Title} from "react-admin";
import useState from "react-usestateref";

import Product from "./Product";

const Calculator = (props) => {
  const [productList, setProductList, productListRef] = useState([]);
  const [ProductsInfo, setProductsInfo, ProductsInfoRef] = useState([]);

  const KodSelect = [
    { key: "100", value: 100 },
    { key: "101", value: 101 },
  ];
  const UprawnieniaSelect = [
    { key: "brak", value: 0 },
    { key: "IW", value: 1 },
    { key: "IB", value: 2 },
    { key: "OR", value: 3 },
    { key: "47.ZN", value: 4 },
    { key: "47.DN", value: 5 },
    { key: "47.ZDN", value: 6 },
    // { key: "cywilna niewidoma ofiara działań wojennych", value: 6 },
  ];
  const IloscMiesRefNaZlecSelect = [
    { key: "1", value: 1 },
    { key: "2", value: 2 },
    { key: "3", value: 3 },
    { key: "4", value: 4 },
    { key: "5", value: 5 },
    { key: "6", value: 6 },
  ];
  const IloscMiesWykSelect = [
    { key: "1", value: 1 },
    { key: "2", value: 2 },
    { key: "3", value: 3 },
    { key: "4", value: 4 },
    { key: "5", value: 5 },
    { key: "6", value: 6 },
  ];

  const [productKey, setProductKey] = useState(0);
  const [sumaSzt, setSumaSzt] = useState(0);
  const [sumaWartosc, setSumaWartosc] = useState(0);
  const [LimitRef, setLimitRef, LimitRefRef] = useState(0);
  const [Kod, setKod] = useState(0);
  const [kodTmp, setKodTmp] = useState(0);
  const [IloscMiesWyk, setIloscMiesWyk, IloscMiesWykRef] = useState(0);
  const [wykLimZaCzas, setWykLimZaCzas] = useState(0);
  const [IloscSztukNaMies, setIloscSztukNaMies, IloscSztukNaMiesRef] =
    useState(0);
  const [pozostalo, setPozostalo] = useState(0);
  const [KwotaRef, setKwotaRef] = useState(0);
  const [IloscMiesRefNaZlec, setIloscMiesRefNaZlec, IloscMiesRefNaZlecRef] =
    useState(0);
  const [Uprawenia, setUprawienia, UprawieniaRef] = useState(0);

  const [sztLimit, setSztLimit, sztLimitRef] = useState(9999999);

  let tmpKwotaRef = 0;

  const refLimitType0 = 1.7;
  const refLimitType1 = 1;

  const getProducts = async () => {
    const fetchHeaders = () => ({
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    });

    const data = await fetch(
      `${process.env.REACT_APP_API_ENTRYPOINT}/product_refunds?page=1&itemsPerPage=400`,
      {
        method: "GET",
        headers: new Headers(fetchHeaders()),
      }
    ).then((data) => {
      //console.log(data);
      try {
        data = data.json();
        return data;
      } catch (error) {
        return [];
      }
    });
    //console.log(data?.["hydra:member"]);
    setProductsInfo(data ? data["hydra:member"] && data["hydra:member"] : []);
    // return data ? data["hydra:member"] && data["hydra:member"] : [];
  };

  const AddProduct = () => {
    const tmpArr = Object.assign([], productListRef.current);
    tmpArr.push({ keyProduct: productKey, count: 0, value: 0, valueOne: 0 });
    setProductList(tmpArr);

    setProductKey(productKey + 1);
  };

  const RemoveProduct = (key) => {
    if (
      productListRef.current?.length > 1 &&
      productListRef.current?.filter(Boolean)?.length > 1
    ) {
      let tmp = productListRef.current;
      delete tmp[key];
      setProductList(tmp);
      return true;
    }
    return false;
  };

  const updateSum = (Key, Count, Value, RefundLimit, ValueOne) => {
    let tmp = productListRef.current.map((el) =>
      el.keyProduct == Key
        ? {
            ...el,
            count: Count,
            value: Value,
            refundLimit: RefundLimit,
            valueOne: ValueOne,
          }
        : el
    );

    setSumaSzt(
      tmp.length > 0
        ? tmp
            .map((e) => {
              return isNaN(e.count) || e.count == null || e.count == ""
                ? 0
                : parseInt(e.count);
            })
            .reduce((a, b) => {
              return parseInt(a + b);
            })
        : null
    );

    setSumaWartosc(
      tmp.length > 0
        ? tmp
            .map((e) => {
              return isNaN(e.value) || e.value == null
                ? 0
                : parseFloat(e.value);
            })
            .reduce((a, b) => {
              return parseFloat(a + b);
            })
        : null
    );

    setProductList(tmp);

    updateValues();
  };

  const updateValues = () => {
    let sumaRefundacji = 0;
    let sumaRazem = 0;
    let iloscProduktow = 0;
    //let sumaDoplata = 0;

    let iloscDostepnaNaMiesiac =
      IloscSztukNaMiesRef.current * IloscMiesWykRef.current;

    let limit = 0.7;

    if ([1, 2, 3].includes(parseInt(UprawieniaRef.current))) limit = 1;

    productListRef.current.map((prod, index) => {
      iloscProduktow += parseInt(prod?.count);
      console.log(prod);
      if (
        iloscProduktow <= iloscDostepnaNaMiesiac &&
        iloscDostepnaNaMiesiac != 0
      ) {
        if (prod?.valueOne >= prod?.refundLimit) {
          sumaRefundacji += prod?.count * (prod?.refundLimit * limit);
        } else {
          sumaRefundacji += prod?.count * (prod?.valueOne * limit); //prod?.count * (prod?.value * limit);
        }
      } else {
        if (prod?.valueOne >= prod?.refundLimit)
          sumaRefundacji = iloscDostepnaNaMiesiac * (prod?.refundLimit * limit);
        else
          sumaRefundacji += iloscDostepnaNaMiesiac * (prod?.valueOne * limit);
      }
      sumaRazem += prod?.count * prod?.value;
    });
    //sumaDoplata = sumaRazem - sumaRefundacji;
    setKwotaRef(sumaRefundacji);
  };

  useEffect(() => {
    getProducts();
    AddProduct();
  }, []);

  return (
    <Card>
      <Title title="Kalkulator refundacji" />
      <CardContent>
        <div className="container-fluid">
          <div className="jumbotron t-jumbotron">
            <div className="form-group row align-items-center">
              <div className="col-sm-3">Uprawienia</div>
              <div className="col-sm-5">
                <select
                  className="browser-default custom-select"
                  onChange={(e) => {
                    setUprawienia(e.target.value);
                    console.log(UprawieniaRef.current);
                    if ([1, 2, 3].includes(parseInt(UprawieniaRef.current)))
                      setSztLimit(90);
                    else setSztLimit(999999999);
                    updateValues();
                  }}
                >
                  <option selected value={0}>
                    Wybierz opcje
                  </option>
                  {UprawnieniaSelect?.map((x) => (
                    <option value={x.value}>{x.key}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-group row align-items-center">
              <div className="col-sm-3">Ilość sztuk na miesiąc</div>
              <div className="col-sm-5">
                <input
                  type="number"
                  className="form-control"
                  onChange={(e) => {
                    if (
                      e.target.value <= sztLimitRef.current &&
                      e.target.value >= 0
                    )
                      setIloscSztukNaMies(e.target.value);
                    else if (e.target.value > sztLimitRef.current)
                      setIloscSztukNaMies(sztLimitRef.current);
                    else if (e.target.value < 0) setIloscSztukNaMies(0);

                    updateValues();
                  }}
                  value={IloscSztukNaMies}
                  min={0}
                  max={sztLimitRef.current}
                />
              </div>
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-xs-12 col-lg-8">
              <div className="jumbotron t-jumbotron blue">
                <div className="form-group row align-items-center">
                  <div className="col">Ilość miesięcy refundacji na zleceniu</div>
                  <div className="col">
                    <select
                      className="browser-default custom-select"
                      onChange={(e) => {
                        setIloscMiesRefNaZlec(e.target.value);
                        updateValues();
                      }}
                    >
                      <option selected value={0}>
                        Wybierz opcje
                      </option>
                      {IloscMiesRefNaZlecSelect?.map((x) => (
                        <option value={x.value}>{x.key}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="form-group row align-items-center">
                  <div className="col">Ile miesięcy chcesz wykorzystać?</div>
                  <div className="col">
                    <select
                      className="browser-default custom-select"
                      onChange={(e) => {
                        setIloscMiesWyk(e.target.value);
                        updateValues();
                      }}
                    >
                      <option selected value={0}>
                        Wybierz opcje
                      </option>
                      {IloscMiesWykSelect?.map(
                        (x) =>
                          IloscMiesRefNaZlec >= x.value && (
                            <option value={x.value}>{x.key}</option>
                          )
                      )}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xs-12 col-lg-4">
              <div className="form-group align-items-center">
                <div className="col">Sztuk w miesiącach wykorzystanych</div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control-plaintext form-control-lg"
                    readonly
                    value={IloscSztukNaMies * IloscMiesWyk}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="h-25 d-inline-block"></div>

          {productListRef.current?.map((x, index) => {
            return (
              <Product
                keyProduct={index}
                productId={x?.keyProduct}
                ProductsInfo={ProductsInfo}
                RemoveFunc={RemoveProduct}
                UpdateSum={updateSum}
              />
            );
          })}
          <div className="row">
            <div className="col-sm">
              <button
                type="button"
                className="btn btn-success"
                onClick={AddProduct}
              >
                Dodaj produkt
              </button>
            </div>
          </div>
          <div className="h-25 d-inline-block"></div>
          <hr></hr>
          <div className="row">
            <div className="offset-lg-6 col-lg-6">
              <div className="row">
                <div className="big-text col-12">Suma</div>
                <div className="col-6">
                  <span className="ml-1 d-inline">szt.</span>
                  <input
                    type="text"
                    className="form-control-plaintext d-inline text-center auto-w"
                    readonly
                    value={sumaSzt}
                  />
                </div>
                <div className="col-6">
                  <input
                    type="text"
                    className="form-control-plaintext"
                    readonly
                    value={parseFloat(sumaWartosc).toFixed(2) + " zł"}
                  />
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="sumup  jumbotron col-12  mt-5 offset-lg-6 col-lg-6">
            <div className="row">
              <div className="col-12 col-lg-6">Kwota dopłaty pacjenta</div>
              <div className="col-12 col-lg-6">
                <input
                  type="text"
                  className="form-control-plaintext text-lg-right"
                  readonly
                  value={
                    isFinite(KwotaRef)
                      ? parseFloat(
                          sumaWartosc - KwotaRef < 0
                            ? 0
                            : sumaWartosc - KwotaRef
                        ).toFixed(2) + " zł"
                      : (0).toFixed(2) + " zł"
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-6">Kwota refundacji</div>
              <div className="col-12 col-lg-6">
                <input
                  type="text"
                  className="form-control-plaintext text-lg-right"
                  readonly
                  value={
                    isFinite(KwotaRef)
                      ? parseFloat(KwotaRef).toFixed(2) + " zł"
                      : (0).toFixed(2) + " zł"
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-6">Razem</div>
              <div className="col-12 col-lg-6">
                <input
                  type="text"
                  className="form-control-plaintext text-lg-right"
                  readonly
                  value={parseFloat(sumaWartosc).toFixed(2) + " zł"}
                />
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default Calculator;
