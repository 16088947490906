import React, {useEffect, useState} from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {ListButton, Title, useLogout, usePermissions, useQuery,useRefresh} from "react-admin";
import {Redirect} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome, faUserCircle} from "@fortawesome/free-solid-svg-icons";
import authProvider from "../../config/authProvider";

import {useSnackbar} from "notistack";

import PermissionHandler from "../../config/PermissionHandler";
import ConsentCheckedList from "../Consent/ConsentCheckedList";

export default function (props) {
  const {permissions} = usePermissions();
  const logout = useLogout();
  const refresh = useRefresh();

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [passwdError, setPasswdError] = useState("");

  const [show, setShow] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const [inputs, setInputs] = useState({
    email: null,
    firstName: null,
    id: null,
    lastName: null,
    phone: null,
    payer: [],
  });

  if(localStorage.getItem('reload') == 'true')
  {
    console.log('redirect');
    localStorage.setItem('reload',false);

    window.location.reload();
    return false;
  }

  const onChangeForField = ({target}) => {
    setInputs({...inputs, [target.name]: target.value});
  }

  useEffect(() => {
    if (data != undefined) {
      let payerInfo = data.payer ? JSON.parse(data.payer) : null;
      setInputs({
        ...inputs,
        id: data.id,
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phone,
        payer: payerInfo,
      });
    }
  }, [isFetched]);

  /////// pk_users/me fetching
  const {data, loading, error} = useQuery({
    type: "getOne",
    payload: {id: `pk_users/me`},
  });
  if (data != undefined && isFetched == false) {
    setIsFetched(true);
  }
  /////// pk_users/me fetching end

  const showModal = () => {
    setShow(true);
  };

  const hideModal = () => {
    setShow(false);
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    let consents = Array.from(document.querySelectorAll('input[type="checkbox"]')).map(x => [x.value,x.checked]);
    consents = JSON.stringify(consents);
    authProvider.saveConsents({consents}).then(
      (response) => {
        enqueueSnackbar(response.message, {
          variant: "info",
          autoHideDuration: 10000,
        });
      },
    );

    if (
      inputs.password == inputs.password2 &&
      inputs.password &&
      inputs.password.length > 3
    ) {
      setPasswdError("");
      const password = inputs.password;
      const password2 = inputs.password2;
      authProvider.changePassword({password, password2}).then(
        (response) => {
          enqueueSnackbar(response.message, {
            variant: "info",
            autoHideDuration: 10000,
          });
          logout().then(() => {
            enqueueSnackbar("Proszę zalogować się ponownie", {
              variant: "warning",
              autoHideDuration: 10000,
            });
          });
        },
        (error) => {
          const resMessage =
            (error.response && error.response.message) ||
            error.message ||
            error.toString();
          enqueueSnackbar(resMessage, {
            variant: "warning",
            autoHideDuration: 10000,
          });
        }
      );
    } else if (inputs.password !== inputs.password2) {
      setPasswdError("Hasła różnią się");
    } else if (inputs.password && inputs.password.length < 3) {
      setPasswdError("Długość hasła jest zbyt mała");
    } else if (!inputs.password && !inputs.password2) {
      setPasswdError("");
    }
  };

  return (
    <Card>
      <Title title="Moje dane"/>
      <CardContent>
        <form onSubmit={handleSubmit}>
          <div className="d-flex w-100 justify-content-around">
            {PermissionHandler(permissions, "MyData", "PayerDataFV") && (
              /* col1 */
              <div className="col-xs-6">
                <h1 className="text-center">
                  <FontAwesomeIcon icon={faHome}/>
                  <span className="badge badge-danger">Dane płatnika - FV</span>
                </h1>
                <div className="table">
                  <table className="table table-striped">
                    <tbody>
                      <tr>
                        <th className="col-form-label">Nazwa</th>
                        <td className="col-md">
                          <span>{inputs.payer && inputs.payer.name}</span>
                        </td>
                      </tr>
                      <tr>
                        <th className="col-form-label">NIP</th>
                        <td className="col-md">
                          <span>{inputs.payer && inputs.payer.nip}</span>
                        </td>
                      </tr>
                      <tr>
                        <th className="col-form-label">Miejscowość</th>
                        <td className="col-md">
                          <span>{inputs.payer && inputs.payer.city}</span>
                        </td>
                      </tr>
                      <tr>
                        <th className="col-form-label">Kod pocztowy</th>
                        <td className="col-md">
                          {<span>{inputs.payer && inputs.payer.postCode}</span>}
                        </td>
                      </tr>
                      <tr>
                        <th className="col-form-label">Ulica</th>
                        <td className="col-md">
                          <span>{inputs.payer && inputs.payer.street}</span>
                        </td>
                      </tr>
                      <tr>
                        <th className="col-form-label">Województwo</th>
                        <td className="col-md">
                          <span>{inputs.payer && inputs.payer.voivodeship}</span>
                        </td>
                      </tr>
                      <tr>
                        <th className="col-form-label">Numer klienta</th>
                        <td className="col-md">
                          <span>{inputs.payer && inputs.payer.clientNumber}</span>
                        </td>
                      </tr>
                      <tr>
                        <th className="col-form-label">Sieć</th>
                        <td className="col-md">
                          <span>{inputs.payer && inputs.payer.payerNetworkName}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            {/* col2 */}
            <div className="col-xs-6">
              <h1 className="text-center">
                <FontAwesomeIcon icon={faUserCircle}/>
                <span className="badge badge-primary">Dane kontaktowe</span>
              </h1>
              <div className="table">
                <table className="table table-striped">
                  <tbody>
                    <tr>
                      <th className="col-form-label">Imię</th>
                      <td className="col-md">
                        <span>{inputs.firstName}</span>
                      </td>
                    </tr>
                    <tr>
                      <th className="col-form-label">Nazwisko</th>
                      <td className="col-md">
                        <span>{inputs.lastName}</span>
                      </td>
                    </tr>
                    <tr>
                      <th className="col-form-label">Adres e-mail</th>
                      <td className="col-md">
                        <span>{inputs.email}</span>
                      </td>
                    </tr>
                    <tr>
                      <th className="col-form-label">Numer tel.</th>
                      <td className="col-md">
                        <span>{inputs.phone}</span>
                      </td>
                    </tr>
                    <tr>
                      <th className="col-form-label">Nowe hasło</th>
                      <td className="col-md">
                        <input
                          type="password"
                          name={"password"}
                          className={
                            passwdError == ""
                              ? "form-control"
                              : "form-control is-invalid"
                          }
                          onChange={onChangeForField}
                          required={inputs.password2}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th className="col-form-label">Powtórz hasło</th>
                      <td className="col-md">
                        <input
                          type="password"
                          name={"password2"}
                          className={
                            passwdError == ""
                              ? "form-control"
                              : "form-control is-invalid"
                          }
                          onChange={onChangeForField}
                          required={inputs.password}
                        />
                        {passwdError != "" && (
                          <span className="text-danger">{passwdError}</span>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className=" w-100">
            {PermissionHandler(permissions, "Consents", "Checked") && (inputs.firstName) && (
              <ConsentCheckedList />
            )}
          </div>
          <div style={{display: "flex", justifyContent: "space-between"}}>
            <input
              type="submit"
              className="btn btn-success btn-lg"
              value="Zapisz"
            />
            <ListButton
              label="Anuluj"
              className={"btn btn-warning btn-lg"}
              basePath={props.basePath}
            />
          </div>

        </form>
      </CardContent>
    </Card>
  );
}
