// 'ROLE_PAYER_VIRTUAL' - deprecated 'ROLE_PAYER_NETWORK' instead
const permissionsInfo = {
  Consents: {
    Create: [],
    Read: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    Checked: ['ROLE_MANAGER', 'ROLE_WORKER'],
    Update: [],
    Delete: [],
  },
  Dashboard: {
    InfoRow: ['ROLE_MANAGER', 'ROLE_WORKER'],
    ContactForm: ['ROLE_PARTNER', 'ROLE_PAYER_VIRTUAL', 'ROLE_PAYER_NETWORK', 'ROLE_PAYER', 'ROLE_MANAGER', 'ROLE_WORKER'],
    ComplaintRow: ['ROLE_PARTNER', 'ROLE_PAYER_VIRTUAL', 'ROLE_PAYER_NETWORK', 'ROLE_PAYER', 'ROLE_MANAGER', 'ROLE_WORKER'],
    //CanBuy: [ 'ROLE_SUPER_ADMIN','ROLE_MANAGER', 'ROLE_WORKER'],
    CanBuy: ['ROLE_MANAGER', 'ROLE_WORKER'],
  },
  InfoPopup: {
    Create: [],
    Read: ['ROLE_PARTNER', 'ROLE_MANAGER', 'ROLE_PAYER_VIRTUAL', 'ROLE_PAYER_NETWORK', 'ROLE_PAYER', 'ROLE_SUPER_ADMIN'],
    Update: [],
    Delete: [],
  },
  Invoices: {
    Create: [],
    Read: ['ROLE_MANAGER', 'ROLE_PAYER_VIRTUAL', 'ROLE_PAYER_NETWORK', 'ROLE_PAYER', 'ROLE_WORKER'],
    Update: [],
    Delete: [],
    GetBalance: ['ROLE_PAYER_VIRTUAL', 'ROLE_PAYER_NETWORK', 'ROLE_PAYER', 'ROLE_MANAGER'],
    BalanceView: ['ROLE_PAYER', 'ROLE_PAYER_VIRTUAL', 'ROLE_PAYER_NETWORK'],
    GetDelayedInvoiceNumber: ['ROLE_MANAGER', 'ROLE_PAYER', 'ROLE_PAYER_VIRTUAL', 'ROLE_PAYER_NETWORK'],
  },
  MyData: {
    PayerDataFV: ['ROLE_PAYER'],
  },
  Orders: {
    Create: [],
    Read: ['ROLE_PAYER_VIRTUAL', 'ROLE_PAYER_NETWORK', 'ROLE_PAYER', 'ROLE_MANAGER', 'ROLE_WORKER'],
    Update: [],
    Delete: [],
    GetDiscount: ['ROLE_PAYER_VIRTUAL', 'ROLE_PAYER_NETWORK', 'ROLE_PAYER'],
  },
  Payers: {
    Create: [],
    Read: ['ROLE_PARTNER', 'ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_PAYER_VIRTUAL', 'ROLE_PAYER_NETWORK', 'ROLE_TRAINER'],
    Update: [],
    Delete: [],
  },
  PayerNetworks: {
    Create: [],
    Read: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER'],
    Update: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
    Delete: [],
  },
  Partners: {
    Create: [],
    Read: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER'],
    Update: [],
    Delete: [],
  },
  PartnerUsers: {
    Create: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER'],
    Read: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER'],
    Update: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
    Delete: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
  },
  PayerNetworkUsers: {
    Create: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_PAYER_VIRTUAL', 'ROLE_PAYER_NETWORK', 'ROLE_PARTNER'],
    Read: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_PAYER_VIRTUAL', 'ROLE_PAYER_NETWORK', 'ROLE_PARTNER'],
    Update: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_PAYER_VIRTUAL', 'ROLE_PAYER_NETWORK', 'ROLE_PARTNER'],
    Delete: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_PAYER_VIRTUAL', 'ROLE_PAYER_NETWORK', 'ROLE_PARTNER'],
  },
  PkUsers: {
    Create: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
    Read: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
    Update: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
    Delete: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
  },
  PayerUsers: {
    Create: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_PAYER_VIRTUAL', 'ROLE_PAYER_NETWORK'],
    Read: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_PAYER_VIRTUAL', 'ROLE_PAYER_NETWORK'],
    Update: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_PAYER_VIRTUAL', 'ROLE_PAYER_NETWORK'],
    Delete: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_PAYER_VIRTUAL', 'ROLE_PAYER_NETWORK'],
  },
  PayerDiscounts: {
    Create: [],
    Read: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
    Update: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
    Delete: [],
  },
  PickupPoints: {
    Create: [],
    Read: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_PAYER', 'ROLE_PAYER_VIRTUAL', 'ROLE_PAYER_NETWORK', 'ROLE_WORKER', 'ROLE_MANAGER'],
    Update: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
    Delete: [],
    NumberStatusEdit: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
  },
  PriceLists: {
    Create: [],
    Read: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
    Update: [],
    Delete: [],
  },
  Samples: {
    Create: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
    Read: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
    Update: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
    Delete: ['ROLE_SUPER_ADMIN'],
  },
  Tutorials: {
    Create: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_TRAINER'],
    Read: [
      'ROLE_SUPER_ADMIN',
      'ROLE_ADMIN',
      'ROLE_MANAGER',
      'ROLE_WORKER',
      'ROLE_TRAINER',
      'ROLE_PAYER',
      'ROLE_PAYER_VIRTUAL', 'ROLE_PAYER_NETWORK',
    ],
    Update: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_TRAINER'],
    Delete: ['ROLE_SUPER_ADMIN'],
    AdminViewRead: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_TRAINER'],
    WorkerViewRead: ['ROLE_MANAGER', 'ROLE_WORKER', 'ROLE_TRAINER'],
    PayerViewRead: ['ROLE_PAYER', 'ROLE_PAYER_VIRTUAL', 'ROLE_PAYER_NETWORK'],
  },
  RefundMenu: {
    Create: [],
    Read: ['ROLE_MANAGER', 'ROLE_WORKER', 'ROLE_SUPER_ADMIN'],
    Update: [],
    Delete: [],
  },
  RefundCalc: {
    Create: [],
    Read: ['ROLE_MANAGER', 'ROLE_WORKER', 'ROLE_SUPER_ADMIN'],
    Update: [],
    Delete: [],
  },
  RefundUpload: {
    Create: [],
    Read: [],
    //Read: ['ROLE_MANAGER', 'ROLE_WORKER', 'ROLE_SUPER_ADMIN'],
    Update: [],
    Delete: [],
  },
  Workers: {
    Create: ['ROLE_MANAGER', 'ROLE_PAYER', 'ROLE_PAYER_VIRTUAL', 'ROLE_PAYER_NETWORK'],
    Read: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_PAYER', 'ROLE_PAYER_VIRTUAL', 'ROLE_PAYER_NETWORK'],
    Update: ['ROLE_MANAGER', 'ROLE_PAYER', 'ROLE_PAYER_VIRTUAL', 'ROLE_PAYER_NETWORK'],
    Delete: ['ROLE_MANAGER', 'ROLE_PAYER', 'ROLE_PAYER_VIRTUAL', 'ROLE_PAYER_NETWORK'],
    CanLogin: [ 'ROLE_SUPER_ADMIN' ]
  },
  Default: {
    default: [],
  },
};

const PermissionHandler = (
  permissions = null,
  moduleName = 'Default',
  actionName = 'default'
) => {
  let result = false;

  if (
    permissions &&
    moduleName &&
    actionName &&
    permissionsInfo.hasOwnProperty(moduleName)
  )
    permissions.split(',').map((x) => {
      if (permissionsInfo[moduleName][actionName])
        if (permissionsInfo[moduleName][actionName].includes(x)) result = true;
    });

  return result;
};

export default PermissionHandler;
