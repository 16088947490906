import * as React from "react";

export const CustomTextField = ({ record = {}, source, txtLabel, label }) => {
  return label ? (
    <>{record[source] ? record[source] : "-"}</>
  ) : (
    <div className="d-flex flex-row">
      {txtLabel && (
        <label>
          <strong>{txtLabel}:&nbsp;&nbsp;</strong>
        </label>
      )}
      <p>{record[source] ? record[source] : "-"}</p>
    </div>
  );
};

export const CustomNumberFieldWith2Decimal = ({
  record = {},
  source,
  txtLabel,
  label,
  currency = null,
}) => {
  return label ? (
    <>
      {record[source]
        ? `${parseFloat(record[source]).toFixed(2)} ${currency ? currency : ""}`
        : "-"}
    </>
  ) : (
    <div className="d-flex flex-row">
      {txtLabel && (
        <label>
          <strong>{txtLabel}:&nbsp;&nbsp;</strong>
        </label>
      )}
      <p>
        {record[source]
          ? `${parseFloat(record[source]).toFixed(2)} ${
              currency ? currency : ""
            }`
          : "-"}
      </p>
    </div>
  );
};

export const CustomDateField = ({ record = {}, source, txtLabel }) => {
  return (
    <div className="d-flex flex-row">
      <label>
        <strong>{txtLabel}:&nbsp;&nbsp;</strong>
      </label>
      <p>{record[source] ? formatDate(record[source]) : "-"}</p>
    </div>
  );
};

export function formatDate(date, showTime = true) {
  if (!date) return "-";
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear(),
    hours = d.getHours(),
    minutes = d.getMinutes(),
    seconds = d.getSeconds();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  if (String(hours).length < 2) hours = "0" + hours;
  if (String(minutes).length < 2) minutes = "0" + minutes;
  if (String(seconds).length < 2) seconds = "0" + seconds;

  let result = [day, month, year].join(".");
  result = showTime ? `${result}, ${hours}:${minutes}:${seconds}` : result;

  return result;
}
