import * as React from "react";
import {DateField, Filter, FunctionField, List, ReferenceManyField, TextField, useQuery,} from "react-admin";
import {CustomActionsButtons} from "../CustomFields/CustomActionButtonsField";
import CustomDataGrid from "../CustomElements/CustomDataGrid";
import CustomActionBar from "../CustomElements/CustomActionBar";
import EmptyList from "../EmptyList";
import {formatDate} from "../CustomFields/CustomFields";

import PermissionHandler from "../../config/PermissionHandler";
import CustomBooleanField from "../CustomFields/CustomBooleanField";
import {CustomTextInput} from "../CustomInputs/CustomInputs";

import {Card, CardContent} from "@material-ui/core";

import md5 from "md5";

const pbSectionsCount = (completed, all) => {
  //console.log(completed, all);

  return (
    <div className="progress">
      <div
        className="progress-bar progress-bar-striped bg-info"
        role="progressbar"
        style={{
          height: "20px",
          width: (completed / all) * 198 + "%",
        }}
      ></div>
    </div>
  );
};

export default (props) => {
  const {
    data: userData,
    loading,
    error,
  } = useQuery({
    type: "getOne",
    payload: { id: `pk_users/me` },
  });

  const { data: lastTutorialData } = useQuery({
    type: "getList",
    resource: "tutorials",
    payload: {
      pagination: { page: 1, perPage: 1 },
      sort: { field: "startDate", order: "DESC" },
      filter: { status: -1 },
    },
  });
  const { data: activeTutorialData } = useQuery({
    type: "getList",
    resource: "tutorials",
    payload: {
      pagination: { page: 1, perPage: 1 },
      sort: { field: "startDate", order: "DESC" },
      filter: { status: 1 },
    },
  });

  const GetHashWorker = () => {
    return md5(userData.email + process.env.REACT_APP_TUTORIAL_SECRET);
  };

  const RenderWorkersCount = ({ data }) => {
    const dataTP = data?.[Object.keys(data)[0]];
    return dataTP
      ? `${dataTP.completedWorkersCount}/${dataTP.allWorkersCount}`
      : null;
  };

  const GetDiscountPayerData = ({ tutorialId, render }) => {
    const { data: tutorialPayerData } = useQuery({
      type: "getList",
      resource: "tutorial_payers",
      payload: {
        pagination: { page: 1, perPage: 1 },
        sort: { field: "startDate", order: "DESC" },
        filter: { tutorial: tutorialId, payer: userData.payerId },
      },
    });

    return tutorialPayerData?.[0] ? render(tutorialPayerData?.[0]) : null;
  };

  const isDiscount = (record) => {
    return record?.isDiscount ? "1%" : "0%";
  };

  return (
    <>
      {PermissionHandler(props.permissions, "Tutorials", "PayerViewRead") &&
        userData?.payerId && (
          <Card className="invoices-list-main mb-2">
            <CardContent className="d-flex w-100 justify-content-around invoices-list-tab">
              {/* col1 */}
              <div className="col-xs-12 col-md-6">
                <div className="table">
                  <table className="table table-striped">
                    {lastTutorialData?.[0]?.id && (
                      <tr>
                        <th className="align-middle">
                          Uzyskany próg rabatowy Seni
                          <br />
                          {`za poprzednie szkolenie (do
                      ${formatDate(
                        lastTutorialData?.[0]?.endDiscountDate,
                        false
                      )})`}
                        </th>
                        <td>
                          <span className=" number badge badge-primary">
                            <GetDiscountPayerData
                              tutorialId={lastTutorialData?.[0]?.id}
                              render={(record) => isDiscount(record)}
                            />
                          </span>
                        </td>
                      </tr>
                    )}
                    {activeTutorialData?.[0]?.id && (
                      <tr>
                        <th className="align-middle">
                          Uzyskany próg rabatowy Seni
                          <br />
                          {`za aktualne szkolenie (do
                      ${formatDate(
                        activeTutorialData?.[0]?.endDiscountDate,
                        false
                      )})`}
                        </th>
                        <td>
                          <span className=" number badge badge-success">
                            <GetDiscountPayerData
                              tutorialId={activeTutorialData?.[0]?.id}
                              render={(record) => isDiscount(record)}
                            />
                          </span>
                        </td>
                      </tr>
                    )}
                  </table>
                </div>
              </div>
              {/* col2 */}
              <div className="col-xs-12 col-md-6">
                <div className="table">
                  <table className="table table-striped">
                    {lastTutorialData?.[0]?.id && (
                      <tr>
                        <td className="align-middle" colSpan={2}>
                          <div>
                            <div className="d-flex w-100 justify-content-between">
                              <span>przeszkolowanych pracowników</span>
                              <span>51%</span>
                            </div>

                            <GetDiscountPayerData
                              tutorialId={lastTutorialData?.[0]?.id}
                              render={(record) =>
                                pbSectionsCount(
                                  record.completedWorkersCount,
                                  record.allWorkersCount
                                )
                              }
                            />

                            <div className="d-flex w-100 justify-content-between">
                              <span>
                                <GetDiscountPayerData
                                  tutorialId={lastTutorialData?.[0]?.id}
                                  render={(record) =>
                                    record.completedWorkersCount
                                  }
                                />
                              </span>
                              <span>
                                <GetDiscountPayerData
                                  tutorialId={lastTutorialData?.[0]?.id}
                                  render={(record) => record.allWorkersCount}
                                />
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                    {activeTutorialData?.[0]?.id && (
                      <tr>
                        <td className="align-middle" colSpan={2}>
                          <div>
                            <div className="d-flex w-100 justify-content-between">
                              <span>przeszkolowanych pracowników</span>
                              <span>51%</span>
                            </div>
                            <GetDiscountPayerData
                              tutorialId={activeTutorialData?.[0]?.id}
                              render={(record) =>
                                pbSectionsCount(
                                  record.completedWorkersCount,
                                  record.allWorkersCount
                                )
                              }
                            />
                            <div className="d-flex w-100 justify-content-between">
                              <span>
                                <GetDiscountPayerData
                                  tutorialId={activeTutorialData?.[0]?.id}
                                  render={(record) =>
                                    record.completedWorkersCount
                                  }
                                />
                              </span>
                              <span>
                                <GetDiscountPayerData
                                  tutorialId={activeTutorialData?.[0]?.id}
                                  render={(record) => record.allWorkersCount}
                                />
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </table>
                </div>
              </div>
            </CardContent>
          </Card>
        )}
      <List
        {...props}
        actions={
          <CustomActionBar
            isAddVisible={PermissionHandler(
              props.permissions,
              "Tutorials",
              "Create"
            )}
          />
        }
        bulkActionButtons={false}
        title={`Szkolenia`}
        empty={
          <EmptyList
            title="szkoleń"
            add={PermissionHandler(props.permissions, "Tutorials", "Create")}
          />
        }
        sort={{ field: "startDate", order: "DESC" }}
        filters={
          <Filter>
            <CustomTextInput label="Nazwa" source="name" alwaysOn />
          </Filter>
        }
      >
        {PermissionHandler(props.permissions, "Tutorials", "AdminViewRead") ? (
          <CustomDataGrid>
            <TextField source="name" label="Nazwa szkolenia" />
            <TextField source="statusName" label="Status" />
            <DateField source="startDate" label="Data startu szkolenia" />
            <DateField source="endDate" label="Data końca" />

            <DateField
              source="startDiscountDate"
              label="Początek okresu rabatu"
            />
            <DateField source="endDiscountDate" label="Koniec okresu rabatu" />

            <CustomActionsButtons
              isEditAvailable={PermissionHandler(
                props.permissions,
                "Tutorials",
                "Update"
              )}
              isDescriptionAvailable={true}
              isDeleteAvailable={PermissionHandler(
                props.permissions,
                "Tutorials",
                "Delete"
              )}
              label="Akcje"
            />
          </CustomDataGrid>
        ) : PermissionHandler(
            props.permissions,
            "Tutorials",
            "WorkerViewRead"
          ) ? (
          <CustomDataGrid>
            <TextField source="name" label="Nazwa szkolenia" />

            {/* <TextField source="link" label="Adres URL" /> */}
            <DateField source="endDate" label="Do kiedy" />
            <CustomBooleanField
              source="isCompleted"
              label="realizacja"
              txtTrue="tak"
              txtFalse="nie"
            />

            {userData?.email && (
              <FunctionField
                render={(record) =>
                  record.hasOwnProperty("isCompleted") &&
                  record.isCompleted == false &&
                  record?.status == 1 && (
                    <a
                      className="btn btn-outline-success"
                      href={`https://tzmo.e-lea.com/anonymous/taking_courses/courses/${
                        record.courseId
                      }?group_id=${GetHashWorker()}`}
                      target="_blank"
                      role="button"
                    >
                      Wykonaj
                    </a>
                  )
                }
              />
            )}
          </CustomDataGrid>
        ) : PermissionHandler(
            props.permissions,
            "Tutorials",
            "PayerViewRead"
          ) ? (
          <CustomDataGrid>
            <TextField source="name" label="Nazwa szkolenia" />
            <TextField source="statusName" label="Status" />
            <DateField source="startDate" label="Data startu" />
            <DateField source="endDate" label="Data końca" />
            <ReferenceManyField
              target="tutorial"
              reference="tutorial_payers"
              filter={{ payer: userData?.payerId }}
              label="Ilość przeszkolonych pracowników"
            >
              <RenderWorkersCount />
            </ReferenceManyField>
            <DateField source="startDiscountDate" label="Początek okresu rabatu" />
            <DateField source="endDiscountDate" label="Koniec okresu rabatu" />
          </CustomDataGrid>
        ) : null}
      </List>
    </>
  );
};
