import * as React from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {Title} from 'react-admin';

export default () => (
  <Card>
    <Title title="Strony nie znaleziono" />
    <CardContent>
      <h1>404: Strony nie znaleziono</h1>
    </CardContent>
  </Card>
);
