import React, {useEffect, useState} from "react";
import {AutocompleteInput, DateField, Filter, List, ReferenceInput, TextField, useDataProvider,} from "react-admin";
import CustomBooleanField from "../CustomFields/CustomBooleanField";
import {CustomActionsButtons} from "../CustomFields/CustomActionButtonsField";
import {CustomDateInput, CustomSelectInput, CustomTextInput,} from "../CustomInputs/CustomInputs";
//import CustomDataGrid from "./OldCustomDataGrid";
import CustomDataGrid from "../CustomElements/CustomDataGrid";
import {Card, CardContent} from "@material-ui/core";
import EmptyList from "../EmptyList";
import PermissionHandler from "../../config/PermissionHandler";

export default function (props) {
  const dataProvider = useDataProvider();
  const [data, setData] = useState();
  const [delayedInvoiceNum, setDelayedInvoiceNum] = useState();
  const [isFetched, setIsFetched] = useState(false);
  const [inputs, setInputs] = useState({
    maxDebit: null,
    currentDebit: null,
    remainingDebit: null,
    maxTime: null,
    delayedInvoiceNumber: null
  });
  /////// payer/get_balance fetching
  useEffect(() => {
    if (PermissionHandler(props.permissions, "Invoices", "GetBalance")) {
      dataProvider
        .getOne("", {id: "payers/get_balance"})
        .then(({data: dataGetBalance}) => {
          setData(dataGetBalance);
          dataProvider.getOne("", {id: dataGetBalance.delayedInvoice}).then(
            ({data: dataInvoice}) => {
              setDelayedInvoiceNum(
                dataInvoice && dataInvoice.number ? dataInvoice.number : null
              );
            },
            () => {
              setDelayedInvoiceNum("brak");
            }
          );
        });
      if (data && delayedInvoiceNum) {
        setInputs({
          ...inputs,
          maxDebit: data.maxDebit,
          currentDebit: data.currentDebit,
          remainingDebit: data.remainingDebit,
          maxTime: data.maxTime,
          delayedInvoiceNumber: delayedInvoiceNum
        });
      }
    }
  }, [isFetched || props.permissions]);
  if (data != undefined && delayedInvoiceNum && isFetched == false)
    setIsFetched(true);

  return (
    <>
      {(PermissionHandler(props.permissions, "Invoices", "BalanceView")) && (
        <Card className="invoices-list-main">
          <CardContent className="d-flex w-100 justify-content-around invoices-list-tab">
            {/* col1 */}
            <div className="col-xs-12 col-md-6">
              <div className="table">
                <table className="table table-striped">
                  <tr>
                    <th className="align-middle">Limit obrotowy brutto:</th>
                    <td>
                    <span className=" number badge badge-success">
                      {inputs.maxDebit ?
                        `${inputs.maxDebit
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, " ")} zł` : " - "}
                    </span>
                    </td>
                  </tr>
                  <tr>
                    <th className="align-middle">
                      Termin płatności dla dokumentów:
                    </th>
                    <td>
                    <span className=" number badge badge-primary">
                      {inputs.maxTime ? `${inputs.maxTime}` : " - "}
                    </span>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            {/* col2 */}
            <div className="col-xs-12 col-md-6">
              <div className="table">
                <table className="table table-striped">
                  <tr>
                    <th className="align-middle">
                      Dostępny limit obrotowy brutto:
                    </th>
                    <td>
                    <span className=" number badge badge-danger">
                      {inputs.remainingDebit ?
                        `${inputs.remainingDebit
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, " ")} zł` : " - "}
                    </span>
                    </td>
                  </tr>
                  <tr>
                    <th className="align-middle">
                      Najbardziej opóźniony dokument:
                    </th>
                    <td>
                    <span className=" number badge badge-warning">
                      {inputs.delayedInvoiceNumber}
                    </span>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </CardContent>
        </Card>
      )
      }
      {PermissionHandler(props.permissions, "Invoices", "GetDelayedInvoiceNumber") && (
        <Card className="invoices-list-main">
          <CardContent className="d-flex w-100 justify-content-around invoices-list-tab">
            <div className="col-xs-12 col-md-6">
              <div className="table">
                <table className="table table-striped">
                  <tr>
                    <th className="align-middle">
                      Najbardziej opóźniony dokument:
                    </th>
                    <td>
                    <span className=" number badge badge-warning">
                      {inputs.delayedInvoiceNumber}
                    </span>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </CardContent>
        </Card>
      )}
      <List
        {...props}
        empty={<EmptyList title="płatności" add={false}/>}
        actions={false}
        bulkActionButtons={false}
        title={"Płatności"}
        filters={
          <Filter className="d-flex flex-nowrap mt-5 mb-5">
            <CustomDateInput label="Data po" source="date[after]" alwaysOn/>
            <CustomDateInput
              label="Data przed"
              source="date[before]"
              alwaysOn
            />
            <CustomSelectInput
              label="Status płatności"
              source="status"
              choices={[
                {id: 1, name: "Opłacone"},
                {id: 0, name: "Nieopłacone"},
                {id: 2, name: "Nieopłacone po terminie"},
              ]}
              alwaysOn
            />
            <div style={{marginBottom: "-19px"}} alwaysOn>
              <label>
                <strong>Punkt odbioru:</strong>
              </label>
              <ReferenceInput
                label=""
                source="pickupPointId"
                reference="pickup_points"
                link={false}
                alwaysOn
                filterToQuery={(searchText) => ({internalName: searchText})}
                className="autocompleteinput"
                allowEmpty
              >
                <AutocompleteInput optionText="internalName"/>
              </ReferenceInput>
            </div>
            <CustomTextInput source="number" label="Numer dokumentu" alwaysOn/>
          </Filter>
        }
      >
        <CustomDataGrid rowStyle={postRowStyle}>
          <TextField source="orderNumber" label="Numer zamówienia"/>
          <TextField source="number" label="Numer dokumentu"/>
          <DateField source="dateSet" label="Data wystawienia"/>
          <DateField source="dateToPaid" label="Termin płatności"/>
          <TextField source="pickupPointName" label="Odbiorca"/>
          <CustomBooleanField source="status" label="Status płatności" txtTrue="Opłacone" txtFalse="Nieopłacone"/>
          <TextField source="net" label="Wartość netto"/>
          <TextField source="gross" label="Wartość brutto"/>
          <TextField source="orderSource" label="Rodzaj zamówienia"/>

          <CustomActionsButtons
            label="Szczegóły zamówienia"
            isEditAvailable={PermissionHandler(
              props.permissions,
              "Invoices",
              "Update"
            )}
            isDescriptionAvailable={false}
            isCustomOrdersDescriptionButton={isDescr}
            isCustomInvoiceDownloadButton={isDescr}
          />
        </CustomDataGrid>
      </List>
    </>
  );
}

const isDescr = (props) => {
  return props.record.order && true;
};

const postRowStyle = (record, index) => {
  //console.log(new Date(record.dateToPaid), new Date());
  if (!record.dateToPaid && !record.status) {
    return {
      backgroundColor: "#ffeeba",
    };
  } else
    return {
      backgroundColor:
        !record.status && new Date(record.dateToPaid) < new Date()
          ? "#f5c6cb"
          : "",
    };
};
