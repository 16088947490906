import * as React from "react";
import {Filter, List,  TextField,} from 'react-admin';
import {CustomActionsButtons} from "../CustomFields/CustomActionButtonsField";

import CustomDataGrid from "../CustomElements/CustomDataGrid";
import CustomActionBar from "../CustomElements/CustomActionBar";
import EmptyList from "../EmptyList";

import PermissionHandler from "../../config/PermissionHandler";

import {CustomTextInput} from "../CustomInputs/CustomInputs";

export default (props) => {
  return (
    <List
      {...props}
      empty={<EmptyList title="płatników sieciowych" add={false} />}
      actions={
        <CustomActionBar
          isAddVisible={
            PermissionHandler(props.permissions, "PayerNetworks", "Create")
          }
        />
      }
      filters={
        <Filter className="d-flex flex-nowrap mt-0">
          <CustomTextInput alwaysOn label="Nazwa" source="name" />
        </Filter>
      }
      bulkActionButtons={false}
      title={`Płatnicy sieciowi`}
    >
      <CustomDataGrid>
        <TextField
          source="name"
          label="Nazwa"
          //sortable={false}
        />


        {PermissionHandler(props.permissions, "PayerNetworks", "Update") && (
          <CustomActionsButtons
            label="Akcje"
            isDescriptionAvailable={PermissionHandler(
              props.permissions,
              "PayerNetworks",
              "Read"
            )}
            isDeleteAvailable={false}
          />
        )}

      </CustomDataGrid>
    </List>
  );
};
