import React, {useState} from "react";
import {required, useDataProvider} from "react-admin";

const requiredField = required("Pole wymagane");

function CreateModal(props) {
  const handleClose = (e) => {
    props.onClose();
    e.stopPropagation();
  };

  const [hash, setHash] = useState();

  return (
    <div className="modal" style={{ display: props.isOpen ? "block" : "none" }}>
      <div
        className="bg-secondary"
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          opacity: 0.7,
        }}
        onClick={handleClose}
      />
      <div className="modal-dialog" style={{ top: "25%" }}>
        <form>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Dodać płatnika do sieci
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <label>
                <strong>Podaj hash płatnika:</strong>
              </label>
              <input
                className="form-control"
                onChange={(e) => setHash(e.target.value)}
                required
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                onClick={handleClose}
              >
                Anuluj
              </button>
              <button
                className="btn btn-success"
                type="submit"
                onClick={() => (hash ? props.onConfirm(hash) : null)}
              >
                Dodaj
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default function PostQuickCreateButtonPayers(props) {
  const [state, setState] = useState({
    isModalOpen: false,
  });

  const dataProvider = useDataProvider();

  const hideModal = () => {
    setState({ isModalOpen: false });
  };

  const showModal = () => {
    setState({ isModalOpen: true });
  };

  return (
    <>
      <button className={"btn btn-success btn-lg"} onClick={showModal}>
        Dodaj płatnika do sieci
      </button>
      <CreateModal
        isOpen={state.isModalOpen}
        onConfirm={(hash) => {
          hideModal();
          dataProvider.create("payers/add_hash", { data: { hash: hash } });
        }}
        onClose={() => {
          hideModal();
        }}
      />
    </>
  );
}
