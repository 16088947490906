import * as React from "react";
import {BooleanInput, Create, email, required, SimpleForm,} from "react-admin";
import authProvider from "../../config/authProvider";

import {CustomSelectInput, CustomTextInput,} from "../CustomInputs/CustomInputs";

import {PostBottomToolbarAdd} from "../CustomElements/PostBottomToolbars";

const requiredField = required("Pole wymagane");
const isEmail = [
  required("Pole wymagane"),
  email("Podany jest nie prawidłowy e-mail"),
];

const transform = (data) => ({
  //for CustomSelectInput - transform type from string to int
  ...data,
  type: parseInt(data.type),
});

export default (props) => {
  return (
    <Create
      {...props}
      actions={null}
      title={`Dodaj użytkownika`}
      transform={transform}
    >
      <SimpleForm toolbar={<PostBottomToolbarAdd />}>
        <div className="d-flex w-100 justify-content-around">
          <div className="col-xs-6">
            <div className="table">
              <table className="table table-striped" style={{ width: "600px" }}>
                <tr>
                  <th style={{ width: "30%" }}>Imię</th>
                  <th>
                    <CustomTextInput source="name" validate={requiredField} />
                  </th>
                </tr>
                <tr>
                  <th>Nazwisko</th>
                  <th>
                    <CustomTextInput
                      source="surname"
                      validate={requiredField}
                    />
                  </th>
                </tr>
                <tr>
                  <th>Email</th>
                  <th>
                    <CustomTextInput source="email" validate={isEmail} />
                  </th>
                </tr>
                <tr>
                  <th>Hasło</th>
                  <th>
                    <CustomTextInput
                      source="password"
                      type="password"
                      validate={requiredField}
                    />
                  </th>
                </tr>
                <tr>
                  <th>Typ</th>
                  <th>
                    <CustomSelectInput
                      source="type"
                      validate={requiredField}
                      choices={
                        authProvider.hasRole(["ROLE_SUPER_ADMIN"])
                          ? [
                              { id: 999, name: "Super admin" },
                              { id: 500, name: "Admin" },
                              { id: 405, name: "Szkoleniowiec" },
                              //{ id: 400, name: "Windykacja" },
                              // { id: 110, name: "Płatnik Wirtualny" },
                              // { id: 100, name: "Płatnik" },
                              // { id: 20, name: "Menadżer" },
                              // { id: 10, name: "Pierwszy stół" },
                            ]
                          : authProvider.hasRole(["ROLE_ADMIN"])
                          ? [
                              { id: 500, name: "Admin" },
                              { id: 405, name: "Szkoleniowiec" },
                              //{ id: 400, name: "Windykacja" },
                              // { id: 110, name: "Płatnik Wirtualny" },
                              // { id: 100, name: "Płatnik" },
                              // { id: 20, name: "Menadżer" },
                              // { id: 10, name: "Pierwszy stół" },
                            ]
                          : // : authProvider.hasRole(["ROLE_DEBT_COLLECTION"])
                            // ? [
                            //     { id: 110, name: "Płatnik Wirtualny" },
                            //     { id: 100, name: "Płatnik" },
                            //     { id: 20, name: "Menadżer" },
                            //     { id: 10, name: "Pierwszy stół" },
                            //   ]
                            []
                      }
                    />
                  </th>
                </tr>
                <tr>
                  <th>Status</th>
                  <th>
                    <BooleanInput source="status" label=" " />
                  </th>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};
