import * as React from "react";
import {Create, email, ReferenceInput, required, SimpleForm,} from "react-admin";

import {CustomSelectInput, CustomTextInput,} from "../CustomInputs/CustomInputs";

import {PostBottomToolbarAdd} from "../CustomElements/PostBottomToolbars";

const requiredField = required("Pole wymagane");
const isEmail = [
  required("Pole wymagane"),
  email("Podany jest nie prawidłowy e-mail"),
];

const transform = (data) => ({
  //transform type from string to int
  ...data,
  oracleId: parseInt(data.oracleId),
});

export default (props) => {
  return (
    <Create
      {...props}
      actions={null}
      title={`Dodaj płatnika`}
      transform={transform}
    >
      <SimpleForm toolbar={<PostBottomToolbarAdd />}>
        <div className="d-flex w-100 justify-content-around">
          <div className="col-xs-6">
            <div className="table">
              <table className="table table-striped">
                <tr>
                  <th>Oracle</th>
                  <th>
                    <CustomTextInput
                      source="oracleId"
                      validate={requiredField}
                    />
                  </th>
                </tr>
                <tr>
                  <th>Numer Klienta</th>
                  <th>
                    <CustomTextInput
                      source="clientNumber"
                      validate={requiredField}
                    />
                  </th>
                </tr>
                <tr>
                  <th>Nazwa</th>
                  <th>
                    <CustomTextInput source="name" validate={requiredField} />
                  </th>
                </tr>
                <tr>
                  <th>NIP</th>
                  <th>
                    <CustomTextInput source="nip" validate={requiredField} />
                  </th>
                </tr>
                <tr>
                  <th>Kod pocztowy</th>
                  <th>
                    <CustomTextInput
                      source="postCode"
                      validate={requiredField}
                    />
                  </th>
                </tr>
                <tr>
                  <th>Ulica</th>
                  <th>
                    <CustomTextInput source="street" validate={requiredField} />
                  </th>
                </tr>
                <tr>
                  <th>Miejscowość</th>
                  <th>
                    <CustomTextInput source="city" validate={requiredField} />
                  </th>
                </tr>
                <tr>
                  <th>Województwo</th>
                  <th>
                    <ReferenceInput
                      source="voivodeship"
                      reference="voivodeships"
                      validate={requiredField}
                    >
                      <CustomSelectInput />
                      {/* <SelectInput /> */}
                    </ReferenceInput>
                  </th>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};
