import * as React from "react";
import {Datagrid, email, ReferenceManyField, required, Show, SimpleShowLayout, TextField} from "react-admin";

import {CustomTextField} from "../CustomFields/CustomFields";

import {PostShowActions} from "../CustomElements/PostBottomToolbars";
import CustomBooleanField from "../CustomFields/CustomBooleanField";
import CustomTypeField from "../CustomFields/CustomTypeField";

const requiredField = required("Pole wymagane");
const isEmail = [
  required("Pole wymagane"),
  email("Podany jest nie prawidłowy e-mail"),
];


export default (props) => {
  const CustomTitle = (props) => `Punkt odbioru ${props.record.name}`;

  return (
    <>

      <Show {...props} actions={<PostShowActions/>} title={<CustomTitle />}>
        <SimpleShowLayout >
          <CustomTextField source="name" txtLabel="Nazwa"/>
          <CustomTextField source="postCode" txtLabel="Kod pocztowy"/>
          <CustomTextField source="street" txtLabel="Ulica"/>
          <CustomTextField source="city" txtLabel="Miasto"/>
          <CustomTextField source="klorNumber" txtLabel="Klor"/>
          <CustomTextField source="tzmoNumber" txtLabel="Numer TZMO"/>
          <ReferenceManyField
            label="Pracownicy"
            target="pickupPoints"
            reference="workers"
          >
            <Datagrid>
              <TextField source="name" label="Imię" />
              <TextField source="surname" label="Nazwisko" />
              <TextField source="type" label="Typ" />
              <CustomTypeField source="type" label="Typ" />
              <CustomBooleanField  source="status" label="Status"></CustomBooleanField>
            </Datagrid>
          </ReferenceManyField>
        </SimpleShowLayout>
      </Show>
    </>
  );
};
