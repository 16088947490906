import * as React from "react";
import {Edit, NumberInput, required, SimpleForm, TextField,} from "react-admin";

import {PostBottomToolbarEdit} from "../CustomElements/PostBottomToolbars";

const requiredField = required("Pole wymagane");

const CustomTitle = (props) => `Płatnik ${props.record.payer}`;

const EditRenderer = (props) => {
  return (
    <SimpleForm
      {...props}
      toolbar={
        <PostBottomToolbarEdit
          saveRedirectPath={`/payer_networks/${encodeURIComponent(props.record.payerNetwork)}/show`}
          cancelRedirectPath={`/payer_networks/${encodeURIComponent(props.record.payerNetwork)}/show`}
          isDeleteAvailable={false}
        />
      }
    >
      <TextField source="category" label="Marka" />
      <NumberInput
        source="value"
        label="Wartość [%]"
        min={0}
        max={60}
        step="0.1"
        validate={requiredField}
      />
    </SimpleForm>
  );
};

export default (props) => {
  return (
    <Edit {...props} actions={null} title={<CustomTitle />}>
      <EditRenderer />
    </Edit>
  );
};
