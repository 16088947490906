import React, {useState} from "react";
import {fetchEnd, fetchStart, useQuery} from "react-admin";
import refundationProvider from "../../config/refundationProvider";
import {useSnackbar} from "notistack";
import {useDispatch} from "react-redux";

const UploadDocument = (props) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [file, setFile] = useState(null);
  const [selectedOptions, setSelectedOption] = useState();
  const [selectedPickupPoint, setSelectedPickupPoint] = useState();
  const [isError, setIsError] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [confirmClose, setConfirmClose] = useState(false);

  const { data, loading, error } = useQuery({
    type: "getOne",
    payload: { id: `pk_users/me` },
  });

  const {
    data: PickUpPointsData,
    loading: PickUpPointsLoading,
    error: PickUpPointsError,
  } = useQuery({
    type: "getList",
    resource: "pickup_points",
    payload: {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "id", order: "ASC" },
    },
  });

  const handleClose = () => {
    if (!selectedOptions && !selectedPickupPoint && !file) onClosing();
    else setConfirmClose(true);
  };

  const onClosing = () => {
    setFile(null);
    setIsError(false);
    setConfirmClose(false);
    setSelectedPickupPoint(null);
    setSelectedOption(null);
    props.onClose();
  };

  const saveHandler = () => {
    if (!file) {
      setIsError("Brak załączonego pliku");
      return;
    }
    if (!selectedOptions || selectedOptions == -1) {
      setIsError("Proszę zaznaczyć rodzaj pliku");
      return;
    }
    if (!selectedPickupPoint || selectedPickupPoint == -1) {
      setIsError("Proszę zaznaczyć jedną z aptek");
      return;
    }

    dispatch(fetchStart());
    setLoadingFile(true);
    const formData = new FormData();

    let pp = PickUpPointsData.find((el) => el.id == selectedPickupPoint);

    formData.append("pickupPointName", pp.name);
    formData.append("pickupPointPostCode", pp.postCode);
    formData.append("pickupPointStreet", pp.street);
    formData.append("pickupPointVoivodeship", pp.voivodeship.split("/")[2]);
    formData.append("fileType", selectedOptions);
    formData.append("file", file);
    refundationProvider
      .uploadDocument(formData)
      .then((res) => {
        if (res.status === 201) {
          setFile(null);
          setIsError(false);
          enqueueSnackbar("Dodanie pliku przebiegło pomyślnie", {
            variant: "success",
            autoHideDuration: 5000,
          });
          onClosing();
        } else if (res.status >= 400) {
          enqueueSnackbar("Wystąpił błąd", {
            variant: "error",
            autoHideDuration: 5000,
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar("Wystąpił błąd: " + err.message, {
          variant: "error",
          autoHideDuration: 5000,
        });
      })
      .finally(() => {
        setLoadingFile(false);
        dispatch(fetchEnd());
      });
  };

  return (
    <div className="modal" style={{ display: props.isOpen ? "block" : "none" }}>
      <div
        className="bg-secondary"
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          opacity: 0.7,
        }}
        onClick={loadingFile ? null : handleClose}
      />
      {data && PickUpPointsData && !loading && !PickUpPointsLoading ? (
        <>
          <div className="modal-dialog" style={{ top: "10%" }}>
            {!confirmClose ? (
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Dodaj plik</h5>
                </div>
                <form className="modal-body">
                  <div className="custom-file">
                    <input
                      type="file"
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                      }}
                      className="custom-file-input"
                      id="inputFile"
                      accept=".doc,.docx,.pdf,.xls,.xlsx"
                    />
                    <label className="custom-file-label" htmlFor="inputFile">
                      {!file ? "Wybierz plik..." : file?.name}
                    </label>
                  </div>
                  <div className="mt-2">
                    <select
                      onChange={(e) => {
                        setSelectedOption(e.target.value);
                      }}
                      value={selectedOptions}
                      className="custom-select"
                      aria-label="Default select example"
                    >
                      <option selected value="-1">
                        - Proszę wybrać rodzaj pliku -
                      </option>
                      <option value="1">Zestawienie</option>
                      <option value="2">Skan zlecenia</option>
                      <option value="3">Nota księgowa</option>
                    </select>
                  </div>
                  <div className="mt-2">
                    <select
                      onChange={(e) => {
                        setSelectedPickupPoint(e.target.value);
                      }}
                      value={selectedPickupPoint}
                      className="custom-select"
                    >
                      <option value={-1}>- Proszę wybrać aptekę -</option>
                      {PickUpPointsData.map((pp) => {
                        return <option value={pp.id}>{pp.internalName}</option>;
                      })}
                    </select>
                  </div>
                  {isError && <p className="text-danger">{isError}</p>}
                </form>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleClose}
                    disabled={loadingFile}
                  >
                    Zamknij
                  </button>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={saveHandler}
                    disabled={loadingFile}
                  >
                    {loadingFile && (
                      <div
                        className="spinner-border spinner-border-sm mr-2"
                        role="status"
                      />
                    )}
                    Zapisz
                  </button>
                </div>
              </div>
            ) : (
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Na pewno chcesz zamknąć?</h5>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={onClosing}
                    disabled={loadingFile}
                  >
                    Zamknij
                  </button>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => setConfirmClose(false)}
                    disabled={loadingFile}
                  >
                    Powrót
                  </button>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <h1>Ładowanie...</h1>
      )}
    </div>
  );
};

export default UploadDocument;
