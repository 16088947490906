import * as React from "react";
import {Edit, required, SimpleForm} from "react-admin";

import {CustomTextInput} from "../CustomInputs/CustomInputs";
import {PostBottomToolbarEdit} from "../CustomElements/PostBottomToolbars";

import PermissionHandler from "../../config/PermissionHandler";

const requiredField = required("Pole wymagane");

const transform = (data) => ({
  //for max_orders - transform type from string to int
  ...data,
  max_order: parseInt(data.max_order),
});

export default (props) => {
  const CustomTitle = (props) => `Próbka ${props.record.name}`;

  return (
    <Edit
      {...props}
      actions={null}
      title={<CustomTitle />}
      transform={transform}
    >
      <SimpleForm
        toolbar={
          <PostBottomToolbarEdit
            saveRedirectPath="/samples"
            isDeleteAvailable={PermissionHandler(
              props.permissions,
              "Samples",
              "Delete"
            )}
          />
        }
      >
        <div className="d-flex w-100 justify-content-around">
          <div className="col-xs-6">
            <div className="table">
              <table className="table table-striped" style={{ width: "600px" }}>
                <tr>
                  <th className="col-form-label" style={{ width: "30%" }}>
                    Nazwa
                  </th>
                  <th className="col-md">
                    <CustomTextInput source="name" validate={requiredField} />
                  </th>
                </tr>
                <tr>
                  <th className="col-form-label">SKU</th>
                  <th className="col-md">
                    <CustomTextInput source="sku" validate={requiredField} />
                  </th>
                </tr>
                <tr>
                  <th className="col-form-label">
                    Maksymanlna ilość do zamówienia
                  </th>
                  <th className="col-md">
                    <CustomTextInput
                      source="max_order"
                      type={"number"}
                      validate={requiredField}
                    />
                  </th>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </SimpleForm>
    </Edit>
  );
};
