import * as React from "react";
import {List, TextField} from "react-admin";
import {CustomActionsButtons} from "../CustomFields/CustomActionButtonsField";
import CustomDataGrid from "../CustomElements/CustomDataGrid";
import CustomActionBar from "../CustomElements/CustomActionBar";
import EmptyList from "../EmptyList";

import PermissionHandler from "../../config/PermissionHandler";

export default (props) => {
  return (
    <List
      {...props}
      actions={
        <CustomActionBar
          isAddVisible={PermissionHandler(props.permissions, "Samples", "Create")}
        />
      }
      bulkActionButtons={false}
      title={`Próbki`}
      empty={
        <EmptyList
          title="próbek"
          add={PermissionHandler(props.permissions, "Samples", "Create")}
        />
      }
    >
      <CustomDataGrid>
        <TextField source="name" label="Nazwa" />
        <TextField source="sku" label="SKU" />
        <TextField source="maxOrder" label="Maksymanlna ilość do zamówienia" />

        <CustomActionsButtons
          isEditAvailable={PermissionHandler(props.permissions, "Samples", "Update")}
          isDeleteAvailable={PermissionHandler(props.permissions, "Samples", "Delete")}
          label="Akcje"
        />
      </CustomDataGrid>
    </List>
  );
};
