import * as React from "react";
import {Datagrid, DateField, EditButton, ReferenceManyField, Show, SimpleShowLayout, TextField,} from "react-admin";

import {PostShowActions} from "../CustomElements/PostBottomToolbars";
import {CustomTextField} from "../CustomFields/CustomFields";
import PermissionHandler from "../../config/PermissionHandler";


const CustomTitle = (props) => `Płatnik ${props.record.name}`;


export default (props) => {
  const IsEditable = (props) => {
    return (
      props?.record?.category !== "seni-quarter" &&
      props?.record?.category !== "seni-edu" && <EditButton {...props} />
    );
  };

  return (
    <Show {...props} actions={<PostShowActions/>} title={<CustomTitle/>}>
      <SimpleShowLayout>
        <CustomTextField source="oracleId" txtLabel="Oracle ID"/>
        <CustomTextField source="name" txtLabel="Numer klienta"/>
        <CustomTextField source="nip" txtLabel="NIP"/>
        {(PermissionHandler(props.permissions, "PayerDiscounts", "Update")) && (
        <ReferenceManyField
          label="Rabaty"
          target="payer"
          reference="payer_discounts"
          filter={{payer: props.id.split("/")[2]}}
        >
          <Datagrid>
            <TextField source="category" label="Marka"/>
            <TextField source="value" label="Wartość [%]"/>
            <DateField
              source="updatedAt"
              label="Aktualizowany"
              emptyText="brak danych"
            />
            <IsEditable/>
          </Datagrid>
        </ReferenceManyField>
        )}

      </SimpleShowLayout>
    </Show>
  );
};
