import React, {Component} from "react";
import {Redirect} from "react-router-dom";

import authProvider from "../../config/authProvider";

export default class HashLogin extends Component {

  constructor(props) {
    super(props);

    const urlParams = new URLSearchParams(window.location.hash);
    const user_id = urlParams.get('user_id');
    const hash = urlParams.get('hash');

    this.state = {
      redirect: null,
      error: null
    }

    authProvider.loginByHash({user_id, hash}).then(
      (response) => {
        this.setState({redirect: true, error: false});
      },
      (error) => {
        this.setState({redirect: false, error: true});
      }
    );
  }


  render() {
    const {redirect} = this.state;

    if (this.state.redirect === true) {
      return <Redirect to='/mydata'/>;
    }
    if (this.state.error === true) {
      return <Redirect to='/login'/>;
    }

    return (
      <div>Przekierowanie</div>
    );
  }
}
