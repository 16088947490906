import * as React from "react";

const CustomBooleanField = ({
  record = {},
  source,
  txtTrue,
  txtFalse,
  txtLabel,
  label,
}) => {
  return label ? (
    <>
      {record[source]
        ? txtTrue && txtFalse
          ? txtTrue
          : "aktywny"
        : txtTrue && txtFalse
        ? txtFalse
        : "nieaktywny"}
    </>
  ) : (
    <div className="d-flex flex-row">
      {txtLabel && (
        <label>
          <strong>{txtLabel}:&nbsp;&nbsp;</strong>
        </label>
      )}
      <p>
        {record[source]
          ? txtTrue && txtFalse
            ? txtTrue
            : "aktywny"
          : txtTrue && txtFalse
          ? txtFalse
          : "nieaktywny"}
      </p>
    </div>
  );
};

export default CustomBooleanField;
