import * as React from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {Title} from 'react-admin';

export default () => (
  <Card>
    <Title title="Informacja" />
    <CardContent>
      <h1>Punkt został zgłoszony do windykacji.<br />Pojawi się na Twoim koncie w ciągu kilku dni roboczych</h1>
    </CardContent>
  </Card>
);
