const CustomTypeField = ({record = {}, source, customTypes}) => {
  if (customTypes == undefined) {
    switch (record[source]) {
      case 10:
        return "farmaceuta";
      case 20:
        return "kierownik";
      default:
        return "brak";
    }
  } else {
    let result = customTypes.find((x) => x.id === record[source]);
    return result != undefined ? result.name : "brak";
  }
};

export default CustomTypeField;
