import * as React from "react";
import { Route } from "react-router-dom";
import ResetPassword from "../layout/Register/ResetPassword";
import HashLogin from "../layout/Register/HashLogin";
import Calculator from "../layout/Calculator/Calculator";
import MyData from "../layout/MyData/MyData";
import PickupPointsAdded from "../layout/PickupPoints/PickupPointsAdded";
import UserNotificationsList from "../layout/UserNotifications/UserNotificationsList";
import Documents from "../layout/Documents/Documents";
import RefundationMenu from "../layout/RefundationMenu/RefundationMenu";
import Contact from "../layout/Contact/Contact";

export default [
  // <Route exact path="/signin" component={RegisterPayer} noLayout />,
  <Route exact path="/reset_password" component={ResetPassword} noLayout />,
  <Route exact path="/login_hash" component={HashLogin} noLayout />,
  <Route exact path="/calculator" component={Calculator} />,
  <Route exact path="/mydata" component={MyData} />,
  <Route exact path="/pickup_points-info" component={PickupPointsAdded} />,
  <Route exact path="/user-notifications" component={UserNotificationsList} />,
  <Route exact path="/documents" component={Documents} />,
  <Route exact path="/refundation-menu" component={RefundationMenu} />,
  <Route exact path="/contact-form" component={Contact} />,
];
