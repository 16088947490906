import * as React from "react";
import {Button, DeleteButton, EditButton, Link, ShowButton,} from "react-admin";
import md5 from "md5";

const CustomEditButton = (props) => {
  return (
    <EditButton
      className={"btn btn-outline-success btn-sm"}
      {...props}
      label={"Edytuj"}
    />
  );
};

export const CustomActionButtonEdit = (props) => {
  return <CustomEditButton {...props} />;
};

const CustomDeleteButton = (props) => {
  return (
    <DeleteButton
      className={"btn btn-outline-danger btn-sm"}
      {...props}
      label={"Usuń"}
    />
  );
};

export const CustomActionButtonDelete = (props) => {
  return <CustomDeleteButton {...props} />;
};

const CustomOrdersDescriptionButton = ({ props, record }) => {
  return (
    <Button
      className={"btn btn-outline-secondary btn-sm"}
      {...props}
      label={"Podgląd zamówienia"}
      component={Link}
      to={{
        pathname: `/orders/${encodeURIComponent(record.order)}/show`,
      }}
    />
  );
};
const CustomInvoiceDownloadButton = ({ props, record }) => {

    return (typeof record.downloadUrl != 'undefined') && (
    <Button
      className={"btn btn-outline-secondary btn-sm"}
      {...props}
      label={"Pobierz fakturę XML"}
      component={Link}
      onClick={() => {
        window.open(record.downloadUrl, '_blank').focus();
      }}
    />
  );
};

const CustomAddToCartButton = ({ props, record }) => {
  return (
    <Button
      className={"btn btn-outline-secondary btn-sm"}
      {...props}
      label={"Dodaj do koszyka"}
      onClick={() => {
        console.log(record.id);
      }}
    />
  );
};

export const CustomShowButton = (props) => {
  return (
    <ShowButton
      className={"btn btn-outline-info btn-sm"}
      {...props}
      label={"Szczegóły"}
    />
  );
};

export const CustomActionsButtons = (props) => {
  return (
    <div
      className={"btn-group"}
      // style={{
      //   display: "flex",
      //   flexDirection: "row",
      //   justifyContent: "space-around",
      //   alignItems: "center",
      // }}
    >
      {props.isEditAvailable ? (
        <CustomEditButton {...props} />
      ) : props.extraEditAvailableFunctionDependsOnRecord ? (
        props.extraEditAvailableFunctionDependsOnRecord(props.record) ? (
          <CustomEditButton {...props} />
        ) : null
      ) : null}
      {props.isDeleteAvailable && <CustomDeleteButton {...props} />}
      {props.isDescriptionAvailable && <CustomShowButton {...props} />}
      {props.isAddToCartAvailable && <CustomAddToCartButton {...props} />}
      {props.isCustomOrdersDescriptionButton && (
        <CustomOrdersDescriptionButton {...props} />
      )}
      {props.isCustomInvoiceDownloadButton && (
        <CustomInvoiceDownloadButton {...props} />
      )}
    </div>
  );
};
