import * as React from "react";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {CreateButton, useListContext} from "react-admin";

export default (props) => {
  const { basePath, resource } = useListContext();
  //console.log(basePath, resource, props);
  return (
    <Card>
      <CardContent>
        <Box textAlign="center" m={1}>
          <Typography variant="h4" paragraph>
            Nie dodano jeszcze <b>{props.title}</b>
          </Typography>
          {props.add && (
            <>
              <Typography variant="h6">Czy chcesz teraz dodać?</Typography>
              <br />
              <CreateButton
                className={"btn btn-success btn-lg"}
                label={"Dodaj"}
                basePath={basePath}
              />
            </>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};
