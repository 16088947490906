import * as React from "react";
import {List, TextField,} from "react-admin";
import {CustomActionsButtons} from "../CustomFields/CustomActionButtonsField";

import CustomDataGrid from "../CustomElements/CustomDataGrid";
import CustomActionBar from "../CustomElements/CustomActionBar";
import EmptyList from "../EmptyList";

import PermissionHandler from "../../config/PermissionHandler";
import CustomBooleanField from "../CustomFields/CustomBooleanField";

const ModuleName = "Consents";

export default (props) => {
  return (
    <List
      {...props}
      empty={<EmptyList title="zgód" add={false} />}
      actions={
        <CustomActionBar
          isAddVisible={
            PermissionHandler(props.permissions, ModuleName, "Create")
          }
        />
      }
      bulkActionButtons={false}
      title={`Zgody`}
    >
      <CustomDataGrid>

        <TextField
          source="name"
          label="Nazwa"
        />
        <TextField
          source="content"
          label="Treść"
        />
        <CustomBooleanField source="status" label="Status" />

        {PermissionHandler(props.permissions, ModuleName, "Update") && (
          <CustomActionsButtons
            label="Akcje"
            isDescriptionAvailable={PermissionHandler(
              props.permissions,
              ModuleName,
              "Read"
            )}
            isDeleteAvailable={false}
          />
        )}

      </CustomDataGrid>
    </List>
  );
};
