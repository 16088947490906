import React, {useState} from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import UploadDocument from "./UploadDocument";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExport} from "@fortawesome/free-solid-svg-icons";

const Documents = () => {
  const [show, setShow] = useState(false);
  const [hover, setHover] = useState(false);

  const showModal = () => {
    setShow(true);
  };

  const hideModal = () => {
    setShow(false);
  };

  const menuElementContainer = {
    display: "flex",
    width: "100%",
    minWidth: 250,
    minHeight: 200,
    maxHeight: 100,
    justifyContent: "center",
    alignItems: "center",
    fontSize: "2em",
    textDecoration: "none",
    flexDirection: "column",
    cursor: "pointer",
  };

  const fontStyle = {
    padding: "2%",
    color: "#d4731c",
    textTransform: "normal",
    textAlign: "center",
    fontSize: "1.25rem",
  };

  const headerFontStyle = {
    color: "#d4731c",
    textTransform: "normal",
    textAlign: "center",
    fontSize: "2rem",
  };

  return (
    <>
      <Card>
        <CardContent>
          <div className="page-content m-2">
            <div className="row">
              <div className="col">
                <div
                  className="dash-card"
                  style={menuElementContainer}
                  onMouseOver={() => setHover(true)}
                  onMouseOut={() => setHover(false)}
                  onClick={showModal}
                >
                  {!hover ? (
                    <>
                      <h2 style={headerFontStyle}>
                        <FontAwesomeIcon icon={faFileExport} inverse className="mr-4"/>
                        Dodaj dokument
                      </h2>
                      <span style={fontStyle}>
                        Przekaż skany dokumentów na dysk współdzielony
                      </span>
                    </>
                  ) : (
                    <span
                      style={headerFontStyle}
                      onMouseOver={() => setHover(true)}
                    >
                      <FontAwesomeIcon icon={faFileExport} inverse className="mr-4"/>
                      Przekaż
                    </span>
                  )}
                  {/* <button
                    type="button"
                    className="btn btn-success"
                    onClick={showModal}
                  >
                    Przekaż
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
      <UploadDocument
        isOpen={show}
        onClose={() => {
          hideModal();
        }}
      />
    </>
  );
};

export default Documents;
