import * as React from "react";
import {DeleteButton, ListButton, SaveButton, Toolbar, TopToolbar} from "react-admin";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUndo} from "@fortawesome/free-solid-svg-icons";

export const PostBottomToolbarEdit = (props) => {
  return (
    <Toolbar
      {...props}
      style={{display: "flex", justifyContent: "space-between"}}
    >
      <SaveButton
        className={"btn btn-success btn-lg"}
        redirect={props.saveRedirectPath ? props.saveRedirectPath : props.basePath}
        submitOnEnter={true}
      />
      {/* disabled={props.invalid} pozwala na wyłączenia przycisku w razie błędu walidacji, ale
        przy pojawieniu błędu walidacji nadpisuje klasę bootstrapową oraz wszytskie klasy (bug react admina?),
        ale inline styles działają */}
      <ListButton
        label="Anuluj"
        className={"btn btn-warning btn-lg"}
        basePath={props.cancelRedirectPath ? props.cancelRedirectPath : props.basePath}
        to={props.cancelRedirectPath ? props.cancelRedirectPath : props.basePath}
      />
      {(props.isDeleteAvailable == undefined ||
        props.isDeleteAvailable == true) && (
        <DeleteButton
          className={"btn btn-danger btn-lg"}
          redirect={props.basePath}
        />
      )}
    </Toolbar>
  );
};

export const PostBottomToolbarAdd = (props) => {
  return (
    <Toolbar
      {...props}
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <SaveButton
        className={"btn btn-success btn-lg"}
        redirect={
          props.saveRedirectPath ? props.saveRedirectPath : props.basePath
        }
        submitOnEnter={true}
      />
      {/* disabled={props.invalid} pozwala na wyłączenia przycisku w razie błędu walidacji, ale
        przy pojawieniu błędu walidacji nadpisuje klasę bootstrapową oraz wszytskie klasy (bug react admina?),
        ale inline styles działają */}
      <ListButton
        label="Anuluj"
        className={"btn btn-warning btn-lg"}
        basePath={props.saveRedirectPath ? props.saveRedirectPath : props.basePath}
      />
    </Toolbar>
  );
};

export const BackToListBtn = ({ basePath }) => {
  return (
    <ListButton
      label="Wstecz"
      className="btn-cancel"
      basePath={basePath}
      icon={<FontAwesomeIcon icon={faUndo} />}
    />
  );
};

export const PostShowActions = (props) => (
  <TopToolbar
    {...props}>
    <ListButton
      label="Anuluj"
      className={"btn btn-warning btn-lg"}
      basePath={props.basePath}
    />
  </TopToolbar>
);

