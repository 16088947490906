import * as React from "react";
import {List, TextField,} from "react-admin";
import {CustomActionsButtons} from "../CustomFields/CustomActionButtonsField";
//import CustomDataGrid from "./OldCustomDataGrid";
import CustomDataGrid from "../CustomElements/CustomDataGrid";
import CustomActionBar from "../CustomElements/CustomActionBar";
import CustomBooleanField from "../CustomFields/CustomBooleanField";
import CustomTypeField from "../CustomFields/CustomTypeField";
import authProvider from "../../config/authProvider";
import EmptyList from "../EmptyList";

import PermissionHandler from "../../config/PermissionHandler";

const types = [
  { id: 999, name: "Super admin" },
  { id: 500, name: "Admin" },
  { id: 405, name: "Szkoleniowiec" },
  //{ id: 400, name: "Windykacja" },
  { id: 110, name: "Płatnik Wirtualny" },
  { id: 100, name: "Płatnik" },
  { id: 20, name: "Menadżer" },
  { id: 10, name: "Pierwszy stół" },
];

const PermissionsHelper = (props) => {
  let isAvailable = false;

  if (authProvider.hasRole(["ROLE_SUPER_ADMIN"])) isAvailable = true;
  else if (authProvider.hasRole(["ROLE_ADMIN"])) {
    if (props.record.type == 999) isAvailable = false;
    else isAvailable = true;
  }

  return (
    <CustomActionsButtons
      isEditAvailable={isAvailable}
      isDeleteAvailable={isAvailable}
      {...props}
    />
  );
};

export default (props) => {
  return (
    <List
      empty={
        <EmptyList
          title="użytkowników"
          add={PermissionHandler(props.permissions, "PkUsers", "Create")}
        />
      }
      {...props}
      actions={
        <CustomActionBar
          isAddVisible={PermissionHandler(
            props.permissions,
            "PkUsers",
            "Create"
          )}
        />
      }
      bulkActionButtons={false}
      title={`Użytkownicy`}
    >
      <CustomDataGrid>
        <TextField source="email" label="E-mail" />
        <CustomTypeField source="type" label="Typ" customTypes={types} />
        <CustomBooleanField source="status" label="Status" />

        <PermissionsHelper label="Akcje" />
      </CustomDataGrid>
    </List>
  );
};
