import * as React from "react";
import {useMemo} from "react";
import {
  Button,
  FunctionField,
  List,
  ReferenceField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TextField,
  useDataProvider,
} from "react-admin";
import {withStyles} from "@material-ui/core";
//import CustomDataGrid from "./CustomDataGrid";
import {CustomDateField, CustomNumberFieldWith2Decimal, CustomTextField,} from "../CustomFields/CustomFields";
import CustomBooleanField from "../CustomFields/CustomBooleanField";
import CustomDataGrid from "../CustomElements/CustomDataGrid";
import EmptyList from "../EmptyList";
import decodeJwt from "jwt-decode";
import md5 from "md5";
import PermissionHandler from "../../config/PermissionHandler";

const CustomTitle = (props) => `Zamówienie ${props.record.orderId}`;
const isECommerceOrder = (props) =>  { return(props.record.zrzam == 'eCommerce') };

export default (props,source) => {

  const dataProvider = useDataProvider();




  const CustomAddToCartButton = (props) => {

    let token = localStorage.getItem("token");
    let decodedToken = decodeJwt(token);
    let username = decodedToken.username;
    let hashMd5 = md5(username + process.env.REACT_APP_MD5_HASH);
    let prestaUrl = localStorage.getItem("shopUrl");

    return (
      <Button
        className={"btn btn-outline-secondary btn-sm"}
        label={"Dodaj do koszyka"}
        onClick={() => {
          let url = prestaUrl+"/superlogin.php?hash=" + hashMd5
            + "&email=" + username + "&token=" +
            token +
            "&timestamp=" +
            Date.now() +
            "&redirect=true&product_sku=" +
            props.record.sku+"|"+props.record.quantity;
          //alert(props.record.sku);
          window.open(url, '_blank').focus();
        }}
      />
    );
  };


  const AddToCartButton = (props) => {

    // console.log(order.order['zrzam']);
    return PermissionHandler(props.permissions, "Dashboard", "CanBuy") ? (
      <CustomAddToCartButton {...props} />
    ) : null ;
  };


  const [netto, setNetto] = React.useState("-");
  const [brutto, setBrutto] = React.useState("-");

  React.useEffect(() => {
    dataProvider.getOne("", {id: props.id}).then(({data}) => {
      setNetto(data.net);
      setBrutto(data.gross);
    });
  });

  const ReferenceManyFieldDisplayBlock = useMemo(() => {
    return withStyles({
      root: {
        display: "block",
      },
    })(ReferenceManyField);
  }, []);

  const ReferenceFieldDisplayBlock = useMemo(() => {
    return withStyles({
      root: {
        display: "block",
        margin: 0,
        padding: 0,
      },
    })(ReferenceField);
  }, []);


  return (
    <Show {...props} title={<CustomTitle/>} >
      <SimpleShowLayout>
        <CustomTextField source="nrZam" txtLabel="Numer zamówienia"/>
        <CustomDateField
          source="date"
          txtLabel="Data wystawienia"
          showTime={true}
        />
        <ReferenceFieldDisplayBlock
          source="pickupPoint"
          reference="pickup_points"
          label=""
          link={false}
        >
          <CustomTextField source="internalName" txtLabel="Punkt odbioru"/>
        </ReferenceFieldDisplayBlock>
        <CustomTextField source="status" txtLabel="Status realizacji"/>
        <CustomBooleanField
          source="paymentStatus"
          txtLabel="Status płatności"
          txtTrue="Opłacone"
          txtFalse="Nieopłacone"
        />
        <CustomTextField source="zrzam" txtLabel="Źródło"/>
        <label>
          <strong>Pozycje zamówienia</strong>
        </label>
        <ReferenceManyFieldDisplayBlock
          reference="product_orders"
          target="order"
          filter={{order: props.id.split("/")[2]}}
          label={null}
        >
          <List actions={null} bulkActionButtons={false} title={" "} empty={<EmptyList title="zamówień" add={false}/>}>
            <CustomDataGrid
              isAddToCartButton={true}
              isFooterOrderOne={true}
              permissions={props.permissions}

              footerOrderOneData={{
                allNet: netto,
                allGross: brutto,
              }}
              //fieldsToSum={["amountNetto", "amountGross"]}
            >
              <TextField source="bloz7" label="BLOZ7" emptyText="-"/>
              <TextField source="ean" label="EAN" emptyText="-"/>
              <TextField source="name" label="Nazwa" emptyText="-"/>
              <CustomNumberFieldWith2Decimal
                source="pricePerUnitNet"
                label="Cena jednostkowa netto"
                currency="zł"
              />
              <CustomNumberFieldWith2Decimal
                source="pricePerUnitGross"
                label="Cena jednostkowa brutto"
                currency="zł"
              />
              <TextField source="quantity" label="ilość sztuk"/>
              <FunctionField
                label="Wartość netto"
                render={(record) =>
                  `${(record.pricePerUnitNet * record.quantity).toFixed(2)} zł`
                }
              />
              <FunctionField
                label="Wartość brutto"
                render={(record) =>
                  `${(record.pricePerUnitGross * record.quantity).toFixed(
                    2
                  )} zł`
                }
              />

              <AddToCartButton isAddToCart={true} {...props} />
            </CustomDataGrid>
          </List>
        </ReferenceManyFieldDisplayBlock>
      </SimpleShowLayout>
    </Show>
  );
};
