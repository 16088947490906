import * as React from "react";
import {Edit, email, ReferenceInput, required, Show, SimpleForm, SimpleShowLayout,} from "react-admin";

import {CustomSelectInput, CustomTextInput,} from "../CustomInputs/CustomInputs";
import PermissionHandler from "../../config/PermissionHandler";

import {PostBottomToolbarEdit} from "../CustomElements/PostBottomToolbars";

const requiredField = required("Pole wymagane");
const isEmail = [
  required("Pole wymagane"),
  email("Podany jest nie prawidłowy e-mail"),
];

const transform = (data) => ({
  //for CustomSelectInput - transform type from string to int
  ...data,
  accountManager: parseInt(data.accountManager),
  accepted: parseInt(data.accepted),
});

export default (props) => {
  function renderSwitch(param) {
    switch (param) {
      case 2:
        return (
          <div className="alert-warning">
            <span className="m-0 p-1">
              Ten punkt został zgłoszony do akceptacji.
              <br />
              Poinformujemy Cię gdy będziesz mógł składać zamówenia
            </span>
          </div>
        );
      case 3:
        return (
          <div className="alert-danger">
            <span className="m-0 p-1">
              Ten punkt został odrzucony przez TZMO.
              <br />
              Możesz edytować jego dane i zgłosić go ponownie
            </span>
          </div>
        );
      default:
        return;
    }
  }

  const Header = ({ record = {}, source, label }) => {
    return (
      <div>
        <span className="text-center">{renderSwitch(record[source])}</span>
      </div>
    );
  };

  const CustomTitle = (props) => `Punkt odbioru ${props.record.name}`;

  return (
    <>
      <Show {...props} actions={null} title={` `}>
        <SimpleShowLayout className="m-0 p-0">
          <Header source="accepted" />
        </SimpleShowLayout>
      </Show>
      <Edit
        {...props}
        actions={null}
        //title={`Punkt odbioru ${props.id}`}
        title={<CustomTitle />}
        transform={transform}
      >
        <SimpleForm
          toolbar={
            <PostBottomToolbarEdit
              // saveRedirectPath="/pickup_points-info"
              isDeleteAvailable={PermissionHandler(
                props.permissions,
                "PickupPoints",
                "Delete"
              )}
            />
          }
        >
          <div className="d-flex w-100 justify-content-around">
            <div className="col-xs-6">
              <div className="table">
                <table className="table table-striped" style={{ width: "600px" }}>
                  <tr>
                    <th className="col-form-label" style={{ width: "30%" }}>
                      Nazwa wewnętrzna dla PK
                    </th>
                    <th className="col-md">
                      <CustomTextInput
                        source="internalName"
                        validate={requiredField}
                      />
                    </th>
                  </tr>
                  <tr>
                    <th className="col-form-label">Nazwa</th>
                    <th className="col-md">
                      <CustomTextInput source="name" validate={requiredField} />
                    </th>
                  </tr>
                  <tr>
                    <th className="col-form-label">Miejscowość</th>
                    <th className="col-md">
                      <CustomTextInput source="city" validate={requiredField} />
                    </th>
                  </tr>
                  <tr>
                    <th className="col-form-label">Kod pocztowy</th>
                    <th className="col-md">
                      <CustomTextInput
                        source="postCode"
                        validate={requiredField}
                      />
                    </th>
                  </tr>
                  <tr>
                    <th className="col-form-label">Ulica</th>
                    <th className="col-md">
                      <CustomTextInput
                        source="street"
                        validate={requiredField}
                      />
                    </th>
                  </tr>
                  {/* <tr>
                    <th className="col-form-label">Numer</th>
                    <th className="col-md">
                      <CustomTextInput
                        source="number"
                        validate={requiredField}
                      />
                    </th>
                  </tr> */}
                  <tr>
                    <th className="col-form-label">Województwo</th>
                    <th className="col-md">
                      <ReferenceInput
                        source="voivodeship"
                        reference="voivodeships"
                        validate={requiredField}
                      >
                        <CustomSelectInput />
                        {/* <SelectInput /> */}
                      </ReferenceInput>
                    </th>
                  </tr>
                  {PermissionHandler(
                    props.permissions,
                    "PickupPoints",
                    "NumberStatusEdit"
                  ) && (
                    <tr>
                      <th className="col-form-label">Numer TZMO</th>
                      <th className="col-md">
                        <CustomTextInput source="tzmoNumber" />
                      </th>
                    </tr>
                  )}
                  {PermissionHandler(
                    props.permissions,
                    "PickupPoints",
                    "NumberStatusEdit"
                  ) && (
                    <tr>
                      <th className="col-form-label">Status</th>
                      <th className="col-md">
                        <CustomSelectInput
                          source="accepted"
                          choices={[
                            { id: 1, name: "zaakceptowany" },
                            // { id: 2, name: "oczekuje na akceptację" },
                            { id: 3, name: "odrzucony" },
                            { id: 4, name: "adres płatnika - do wykluczenia" },
                          ]}
                        />
                      </th>
                    </tr>
                  )}
                </table>
              </div>
            </div>
          </div>
        </SimpleForm>
      </Edit>
    </>
  );
};
