import * as React from "react";
import {cloneElement} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {CreateButton, sanitizeListRestProps, TopToolbar, useListContext,} from "react-admin";
import PostQuickCreateButtonPayers from "./PostQuickCreateButtonPayers";

const toolbarStyles = makeStyles({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

const CustomCreateButton = (props) => {
  return (
    <CreateButton className={"btn btn-success btn-lg"} {...props} label={"Dodaj"} />
  );
};

const CustomActionBar = (props) => {
  const {
    filtersDataFromRecords = null,
    filtersDataChoices = null,
    isAddVisible,
    isPopupAddVisible = false,
    isFiltersVisible = true,
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();
  return (
    <TopToolbar
      //className={[className, toolbarStyles().root]}
      {...sanitizeListRestProps(rest)}
    >
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}

      {/* <CustomFiltersBar
        filtersDataFromRecords={filtersDataFromRecords}
        filtersDataChoices={filtersDataChoices}
      /> */}

      {isAddVisible && <CustomCreateButton basePath={basePath} />}
      {isPopupAddVisible && <PostQuickCreateButtonPayers />}
    </TopToolbar>
  );
};

export default CustomActionBar;
