import React from "react";
import PropTypes from "prop-types";
import {Notification, useDataProvider} from "react-admin";

import {Field, Form} from "react-final-form";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {useSafeSetState, useTranslate} from "ra-core";

import {useSnackbar} from "notistack";

const useStyles = makeStyles((theme) => ({
  hint: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.grey[500],
  },
  form: {
    padding: "0 1em 1em 1em",
    width: "450px",
    margin: "auto",
  },
  input: {
    marginTop: "1em",
    width: "300px",
    margin: "auto",
    display: "flex",
    flexDirection: "row",
  },
  // button: {
  //   width: "50%",
  // },
  icon: {
    marginRight: theme.spacing(1),
  },
  loginLabel: {
    fontWeight: "bold",
    marginRight: "20px",
  },
}));

const styles = {
  resetPassword: {
    main: {
      background: "none",
      height: "auto",
      minHeight: "inherit",
    },
    card: {
      boxShadow: "none",
      marginTop: "0px",
      minWidth: "0px",
    },
    avatar: {
      display: "none",
    },
  },
};

const ResetPassword = (props) => {
  const dataProvider = useDataProvider();

  const { redirectTo } = props;
  const [loading, setLoading] = useSafeSetState(false);
  const translate = useTranslate();
  const classes = useStyles(props);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const submit = (values) => {
    if (!validateEmail(values.username)) {
      enqueueSnackbar("Podany nieprawidłowy e-mail", {
        variant: "warning",
        autoHideDuration: 10000,
      });
      return false;
    }
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/pk_users/reset_password`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: values.username }),
    })
      .then((response) => (response.json().then(responseText => {
        setLoading(false);
        if (responseText.status == "ok") {
          enqueueSnackbar(
            "Jeśli konto powiązane z adresem email istnieje, link do resetu hasła został wysłany na podany email",
            {
              variant: "info",
              autoHideDuration: 10000,
            }
          );
        } else if (responseText.status == "error") {
          enqueueSnackbar("Błędny adres e-mail bądź konto nieaktywne", {
            variant: "warning",
            autoHideDuration: 10000,
          });
        } else {
          enqueueSnackbar("Niewiadomy błąd", {
            variant: "warning",
            autoHideDuration: 10000,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar(
          typeof error === "string"
            ? error
            : typeof error === "undefined" || !error.message
            ? "ra.auth.sign_in_error"
            : error.message,
          {
            variant: "warning",
            autoHideDuration: 10000,
          }
        );
      })));
  };

  return (
    <div className="thin-wrapper">
      <div className="inner login-panel">
        <div className="logo">
          <img alt="logo" src="logo.svg" />
        </div>
        <div className="title">
          <p>
            Platforma zakupowa produktów{" "}
            <span style={{ color: "#e18a3d" }}>Grupy</span> TZMO
          </p>
        </div>

        <Form
          onSubmit={submit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-login">
                <div className="form-group">
                  <label className={classes.loginLabel}>
                    {translate("ra.auth.username")}
                  </label>

                  <Field
                    autoFocus
                    placeholder="admin@example.com"
                    id="username"
                    name="username"
                    //component={Input}
                    component="input"
                    className="form-control"
                    //label={translate("ra.auth.username")}
                    disabled={loading}
                  />
                </div>

                <div className="d-flex flex-wrap">
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disableElevation
                    disabled={loading}
                    className="primary full-width"
                  >
                    {loading && (
                      <CircularProgress
                        className={classes.icon}
                        size={18}
                        thickness={2}
                      />
                    )}
                    {/* {translate("ra.auth.sign_in")} */}
                    {"Przypomnij hasło"}
                  </Button>
                </div>
              </div>
            </form>
          )}
        />
      </div>
      <Notification />
    </div>
  );
};

ResetPassword.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

export default withStyles(styles.resetPassword)(ResetPassword);
