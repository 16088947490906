import * as React from "react";
import {
  Datagrid,
  DateField,
  Edit,
  EditButton,
  email,
  ReferenceInput,
  ReferenceManyField,
  required,
  SimpleForm,
  TextField,
} from "react-admin";

import {CustomSelectInput, CustomTextInput,} from "../CustomInputs/CustomInputs";

import {PostBottomToolbarEdit} from "../CustomElements/PostBottomToolbars";

const requiredField = required("Pole wymagane");
const isEmail = [
  required("Pole wymagane"),
  email("Podany jest nie prawidłowy e-mail"),
];

const transform = (data) => ({
  ...data,
  oracleId: parseInt(data.oracleId),
});

const CustomTitle = (props) => `Płatnik ${props.record.name}`;

export default (props, record) => {
  return (
    <Edit
      {...props}
      actions={null}
      title={<CustomTitle/>}
      transform={transform}
    >
      <SimpleForm toolbar={<PostBottomToolbarEdit/>}>
        <div className="d-flex w-100 justify-content-around">
          {/* col1 */}
          <div className="col-xs-6">
            <div className="table">
              <table className="table table-striped">
                <tr>
                  <th>HASH</th>
                  <th>
                    <CustomTextInput
                      source="hash"
                      validate={false}
                      disabled
                    />
                  </th>
                </tr>
                <tr>
                  <th>Oracle</th>
                  <th>
                    <CustomTextInput
                      source="oracleId"
                      validate={requiredField}
                    />
                    <TextField
                      source="oracleId"
                      label="Numer klienta"
                    />
                  </th>
                </tr>
                <tr>
                  <th>Numer Klienta</th>
                  <th>
                    <CustomTextInput
                      source="clientNumber"
                      validate={requiredField}
                    />
                  </th>
                </tr>
                <tr>
                  <th>Imię</th>
                  <th>
                    <CustomTextInput source="name" validate={requiredField}/>
                  </th>
                </tr>
                <tr>
                  <th>NIP</th>
                  <th>
                    <CustomTextInput source="nip" validate={requiredField}/>
                  </th>
                </tr>
                <tr>
                  <th>Kod pocztowy</th>
                  <th>
                    <CustomTextInput
                      source="postCode"
                      validate={requiredField}
                    />
                  </th>
                </tr>
                <tr>
                  <th>Ulica</th>
                  <th>
                    <CustomTextInput source="street" validate={requiredField}/>
                  </th>
                </tr>
                <tr>
                  <th>Miejscowość</th>
                  <th>
                    <CustomTextInput source="city" validate={requiredField}/>
                  </th>
                </tr>
                <tr>
                  <th>Województwo</th>
                  <th>
                    <ReferenceInput
                      source="voivodeship"
                      reference="voivodeships"
                      validate={requiredField}
                    >
                      <CustomSelectInput/>
                      {/* <SelectInput /> */}
                    </ReferenceInput>
                  </th>
                </tr>
                <ReferenceManyField
                  label="Rabaty"
                  reference="payer_discounts"
                  source="payerDiscounts">
                  <Datagrid>
                    <DateField source="category"/>
                    <DateField source="value"/>
                    {/*<TextField source="category" />*/}
                    {/*<TextField source="value" />*/}
                    <EditButton/>
                  </Datagrid>
                </ReferenceManyField>

              </table>
            </div>
          </div>
        </div>
      </SimpleForm>
    </Edit>
  );
};
