import React, {useEffect} from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {useQuery} from "react-admin";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBandAid,
  faCartPlus,
  faHistory,
  faIdCardAlt,
  faMailBulk,
  faMoneyCheckAlt,
  faReceipt,
  faRuler,
  faSortNumericUpAlt,
  faStoreAlt,
  faTable,
} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {useSnackbar} from "notistack";

import decodeJwt from "jwt-decode";
import md5 from "md5";

import PermissionHandler from "../../config/PermissionHandler";
import InfoPopup from "../Popup/InfoPopup";

const menuElementContainer = {
  display: "flex",
  width: "100%",
  minWidth: 250,
  minHeight: 100,
  maxHeight: 100,
  margin: 10,
  padding: 10,
  justifyContent: "center",
  alignItems: "center",
  fontSize: "2em",
  textDecoration: "none",
};

const fontStyle = {
  padding: "2%",
  color: "#d4731c",
  textTransform: "normal",
  textAlign: "center",
  fontSize: "1rem",
};

const fontStyleBlocked = {
  padding: "2%",
  color: "#d4731c",
  textTransform: "normal",
  textAlign: "center",
  fontSize: "0.75rem",
};

export default (props) => {
  const { data, loading, error } = useQuery({
    type: "getOne",
    payload: { id: `pk_users/me` },
  });

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  useEffect(() => {
    if (localStorage.getItem("notify") == "true" && data != undefined) {
      enqueueSnackbar(
        `Witaj ${data.firstName}. Twoje logowanie przebiegło poprawnie`,
        {
          variant: "success",
          autoHideDuration: 10000,
        }
      );
      if (data.isBlocked == true)
        enqueueSnackbar(
          <>
            Uwaga, posiadasz zaległe płatności. Obecnie składanie zamówień nie
            jest możliwe.
            <br />
            Ureguluj zaznaczone dokumenty lub skontaktuj się z przedstawicielem
            TZMO
          </>,
          {
            variant: "error",
            autoHideDuration: 10000,
          }
        );
      localStorage.setItem("notify", false);
    }
    if(data != undefined)
    {
      localStorage.setItem("shopUrl", data.shopUrl);
    }
  }, [data]);

  var token = localStorage.getItem("token");
  var decodedToken = decodeJwt(token);
  var username = decodedToken.username;
  let hashMd5 = md5(username + process.env.REACT_APP_MD5_HASH);

  return data ? (
    <>
      {/* {PermissionHandler(props.permissions, "InfoPopup", "Read") &&
        !localStorage.getItem("infoPopupShowed19082022") && <InfoPopup />} */}
      <Card>
        <CardContent>
          <div className="page-content m-2">
            {PermissionHandler(props.permissions, "Dashboard", "CanBuy") && (
              <div className="row">
                <div className="col">
                  <a
                    href={
                      data.shopUrl +
                      "/superlogin.php?hash=" +
                      hashMd5 +
                      "&email=" +
                      username +
                      "&token=" +
                      token +
                      "&timestamp=" +
                      Date.now() +
                      "&redirect=true"
                    }
                    target="_blank"
                    style={menuElementContainer}
                    className={`dash-card ${
                      data.isBlocked && "dash-card-disabled"
                    }`}
                  >
                    <FontAwesomeIcon icon={faCartPlus} />
                    {data.isBlocked == true ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          minWidth: "500px",
                        }}
                      >
                        <span style={fontStyle}>Złóż zamówienie</span>

                        <p style={fontStyleBlocked}>
                          Uwaga, posiadasz zaległe płatności. Obecnie składanie
                          zamówień nie jest możliwe.
                          <br />
                          Ureguluj zaznaczone dokumenty lub skontaktuj się z
                          przedstawicielem TZMO
                        </p>
                      </div>
                    ) : (
                      <span style={fontStyle}>Złóż zamówienie</span>
                    )}
                  </a>
                </div>
              </div>
            )}

            <div className="row">
              {PermissionHandler(props.permissions, "Orders", "Read") && (
                <div className="col">
                  <Link
                    to="/orders"
                    style={menuElementContainer}
                    className="dash-card"
                  >
                    <FontAwesomeIcon icon={faHistory} inverse />
                    <span style={fontStyle}>Historia zamówień</span>
                  </Link>
                </div>
              )}

              {PermissionHandler(props.permissions, "Invoices", "Read") && (
                <div className="col">
                  <Link
                    to="/invoices"
                    style={menuElementContainer}
                    className="dash-card"
                  >
                    <FontAwesomeIcon icon={faMoneyCheckAlt} inverse />
                    <span style={fontStyle}>Płatności</span>
                  </Link>
                </div>
              )}
            </div>

            <div className="row">
              {PermissionHandler(props.permissions, "PickupPoints", "Read") && (
                <div className="col">
                  <Link
                    to="/pickup_points"
                    style={menuElementContainer}
                    className="dash-card"
                  >
                    <FontAwesomeIcon icon={faStoreAlt} inverse />
                    <span style={fontStyle}>Punkt odbioru</span>
                  </Link>
                </div>
              )}
              {PermissionHandler(props.permissions, "Workers", "Read") && (
                <div className="col">
                  <Link
                    to="/workers"
                    style={menuElementContainer}
                    className="dash-card"
                  >
                    <FontAwesomeIcon icon={faIdCardAlt} inverse />
                    <span style={fontStyle}>Pracownicy</span>
                  </Link>
                </div>
              )}
              <div className="col">
                <Link
                  to="/mydata"
                  style={menuElementContainer}
                  className="dash-card"
                >
                  <FontAwesomeIcon icon={faTable} inverse />
                  <span style={fontStyle}>Moje dane</span>
                </Link>
              </div>
            </div>

            {/*<div className="row"> <Link to="/clients" style={menuElementContainer} className="dash-card">
          <FontAwesomeIcon icon={faUserFriends} inverse />
          <span style={fontStyle}>Klienci Indywidualni</span>
        </Link> </div>*/}

            {PermissionHandler(
              props.permissions,
              "Dashboard",
              "ComplaintRow"
            ) && (
              <div className="row">
                <div className="col">
                  <a
                    href="https://reklamacje.tzmo.com.pl/reklamacje-ilosciowe/"
                    target="_blank"
                    style={menuElementContainer}
                    className="dash-card"
                  >
                    <FontAwesomeIcon icon={faSortNumericUpAlt} inverse />
                    <span style={fontStyle}>Reklamacje ilościowe</span>
                  </a>
                </div>
                <div className="col">
                  <a
                    href="https://reklamacje.tzmo.com.pl/"
                    target="_blank"
                    style={menuElementContainer}
                    className="dash-card"
                  >
                    <FontAwesomeIcon icon={faBandAid} inverse />
                    <span style={fontStyle}>Reklamacje jakościowe</span>
                  </a>
                </div>
                <div className="col">
                  <Link
                    to="/refundation-menu"
                    style={menuElementContainer}
                    className="dash-card"
                  >
                    <FontAwesomeIcon icon={faReceipt} inverse />
                    <span style={fontStyle}>Refundacja</span>
                  </Link>
                </div>

                <div className="col">
                  <a
                    href="https://seni.pl/pl_PL/tresc/dobor-rozmiaru"
                    target="_blank"
                    style={menuElementContainer}
                    className="dash-card"
                  >
                    <FontAwesomeIcon icon={faRuler} inverse />
                    <span style={fontStyle}>Dobór rozmiaru</span>
                  </a>
                </div>
              </div>
            )}

            {PermissionHandler(props.permissions, "Dashboard", "ContactForm") && (
              <div className="row">
                <div className="col">
                  <Link
                    to="/contact-form"
                    style={menuElementContainer}
                    className="dash-card"
                  >
                    <FontAwesomeIcon icon={faMailBulk} inverse />
                    <span style={fontStyle}>Formularz kontaktowy</span>
                  </Link>
                </div>
              </div>
            )}

          </div>
        </CardContent>
      </Card>
    </>
  ) : null;
};
