import React from "react";
import {addField} from "react-admin";

const renderCustomTextInput = ({
  name,
  type = "text",
  label = null,
  className,
  value,
  onChange,
  style,
  validate,
  required,
  disabled,
  input,
  meta: { touched, error },
}) => (
  <div>
    {label && (
      <label>
        <strong>{label}:&nbsp;&nbsp;</strong>
      </label>
    )}
    <input
      name={name}
      className={className}
      style={style}
      validate={validate}
      required={required}
      disabled={disabled}
      errorText={touched && error ? error : null}
      type={type}
      className={touched && error ? "form-control is-invalid" : "form-control"}
      disableUnderline={true}
      value={value}
      onChange={onChange}
      {...input}
    />
    {touched && error && (
      <span className="text-danger">{error.message ? error.message : error}</span>
    )}
  </div>
);
export const CustomTextInput = addField(renderCustomTextInput);

const renderCustomTextArea = ({
  validate,
  required,
  input,
  meta: { touched, error },
}) => (
  <div>
    <textarea
      validate={validate}
      required={required}
      errorText={touched && error ? error : null}
      type="text"
      className={touched && error ? "form-control is-invalid" : "form-control"}
      disableUnderline={true}
      {...input}
    />
    {touched && error && (
      <span className="text-danger">{error.message ? error.message : error}</span>
    )}
  </div>
);
export const CustomTextArea = addField(renderCustomTextArea);

const renderCustomNumberInput = ({
  validate,
  required,
  input,
  meta: { touched, error },
}) => (
  <div>
    <input
      validate={validate}
      required={required}
      errorText={touched && error ? error : null}
      type="number"
      className={touched && error ? "form-control is-invalid" : "form-control"}
      disableUnderline={true}
      {...input}
    />
    {touched && error && (
      <span className="text-danger">{error.message ? error.message : error}</span>
    )}
  </div>
);
export const CustomNumberInput = addField(renderCustomNumberInput);

const renderCustomSelectInput = ({
  label,
  customSwitch,
  validate,
  required,
  choices,
  source,
  reference,
  input,
  optionText,
  optionId,
  multiple,
  showErrors = true,
  meta: { touched, error },
}) => {
  return (
    <div>
      {label && (
        <label>
          <strong>{label}:&nbsp;&nbsp;</strong>
        </label>
      )}
      <select
        multiple={multiple}
        defaultValue={null}
        validate={validate}
        required={required}
        className={
          showErrors && touched && error
            ? "form-control is-invalid"
            : "form-control"
        }
        errorText={touched && error}
        source={source}
        {...input}
        {...reference}
      >
        <option value={null} />
        {choices
          ? choices.map((x) => (
              <option value={optionId ? x[optionId] : x.id}>
                {customSwitch
                  ? optionText
                    ? customSwitch(x, optionText)
                    : customSwitch(x, "name")
                  : optionText
                  ? x[optionText]
                  : x.name}
              </option>
            ))
          : null}
      </select>
      {showErrors && touched && error && (
        <span className="text-danger">{error.message ? error.message : error}</span>
      )}
    </div>
  );
};
export const CustomSelectInput = addField(renderCustomSelectInput);

const renderCustomDateInput = ({
  label,
  validate,
  required,
  input,
  formatValue = false,
  meta: { touched, error },
}) => {
  return (
    <div>
      {label && (
        <label>
          <strong>{label}:&nbsp;&nbsp;</strong>
        </label>
      )}
      <input
        validate={validate}
        required={required}
        errorText={touched && error ? error : null}
        type="date"
        className={touched && error ? "form-control is-invalid" : "form-control"}
        disableUnderline={true}
        {...input}
        value={
          formatValue
            ? input.value.split("T")[0]
            : //   ? new Date(input.value)
              //       ?.toISOString("sv")
              //       //.split("T")[0]
              //   :
              input.value
        }
      />
      {touched && error && (
        <span className="text-danger">{error.message ? error.message : error}</span>
      )}
    </div>
  );
};
export const CustomDateInput = addField(renderCustomDateInput);

const renderUniqueSelectInput = ({
  customSwitch,
  validate,
  required,
  choices,
  source,
  reference,
  input,
  optionText,
  optionId,
  multiple,
  meta,
  label,
  showErrors,
}) => {
  let choices_unique = [];

  choices_unique = removeDuplicates(choices, source);

  //console.log(choices_unique);
  return (
    <CustomSelectInput
      label={label}
      source={source}
      optionText={optionText}
      optionId={optionId}
      customSwitch={customSwitch}
      validate={validate}
      required={required}
      choices={choices_unique}
      reference={reference}
      input={input}
      multiple={multiple}
      meta={meta}
      showErrors={showErrors}
    />
  );
};
export const UniqueSelectInput = addField(renderUniqueSelectInput);

function removeDuplicates(myArr, prop) {
  return myArr.filter((obj, pos, arr) => {
    return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
}

const renderCustomCheckboxInput = ({
  style,
  validate,
  required,
  disabled,
  input,
  meta: { touched, error },
}) => (
  <div>
    <input
      style={style}
      validate={validate}
      required={required}
      disabled={disabled}
      errorText={touched && error ? error : null}
      type="checkbox"
      className={touched && error ? "form-control is-invalid" : "form-control"}
      disableUnderline={true}
      {...input}
    />
    {touched && error && (
      <span className="text-danger">{error.message ? error.message : error}</span>
    )}
  </div>
);
export const CustomCheckboxInput = addField(renderCustomCheckboxInput);
