import * as React from "react";
import {List, TextField,} from "react-admin";
import {CustomActionsButtons} from "../CustomFields/CustomActionButtonsField";
//import CustomDataGrid from "./OldCustomDataGrid";
import CustomDataGrid from "../CustomElements/CustomDataGrid";
import CustomActionBar from "../CustomElements/CustomActionBar";
import EmptyList from "../EmptyList";

import PermissionHandler from "../../config/PermissionHandler";

export default (props) => {
  return (
    <List
      {...props}
      empty={
        <EmptyList
          title="użytkowników płatników sieciowych"
          add={PermissionHandler(props.permissions, "PayerNetworkUsers", "Create")}
        />
      }
      actions={
        <CustomActionBar
          isAddVisible={PermissionHandler(props.permissions, "PayerNetworkUsers", "Create")}
          isFiltersVisible={false}
        />
        //null
      }
      bulkActionButtons={false}
      title={`Płatnicy użytkownicy`}
    >
      <CustomDataGrid>
        <TextField source="name" label="Imię" />
        <TextField source="surname" label="Nazwisko" />
        <TextField source="email" label="E-mail" />
        <TextField source="phone" label="Numer Telefonu" />
        <TextField source="payerNetworkName" label="Płatnik sieciowy" />

        {PermissionHandler(props.permissions, "PayerNetworkUsers", "Update") && (
          <CustomActionsButtons
            label="Akcje"
            isEditAvailable={PermissionHandler(props.permissions, "PayerNetworkUsers", "Update")}
            isDeleteAvailable={PermissionHandler(props.permissions, "PayerNetworkUsers", "Delete")}
          />
        )}
      </CustomDataGrid>
    </List>
  );
};
