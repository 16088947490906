import * as React from "react";
import {Filter, List, TextField,} from "react-admin";
import {CustomActionsButtons} from "../CustomFields/CustomActionButtonsField";
import {CustomSelectInput, CustomTextInput} from "../CustomInputs/CustomInputs";
//import CustomDataGrid from "./OldCustomDataGrid";
import CustomDataGrid from "../CustomElements/CustomDataGrid";
import CustomActionBar from "../CustomElements/CustomActionBar";

import PermissionHandler from "../../config/PermissionHandler";

import EmptyList from "../EmptyList";

const isAvailable = (record) => {
  if ([2, 3].includes(record.accepted)) return true;
  else return false;
};

const types = [
  {id: 1, name: "zaakceptowany"},
  {id: 3, name: "odrzucony"},
  {id: 4, name: "adres płatnika - do wykluczenia"},
];


export default (props) => {
  return (
    <List
      {...props}
      empty={
        <EmptyList
          title="punktów odbioru"
          add={PermissionHandler(props.permissions, "PickupPoints", "Create")}
        />
      }
      actions={
        <CustomActionBar
          isAddVisible={PermissionHandler(
            props.permissions,
            "PickupPoints",
            "Create"
          )}
        />
      }
      filters={
        <Filter className="d-flex flex-nowrap mt-0">
          {PermissionHandler(props.permissions, "PickupPoints", "Update") && (
            <CustomSelectInput
              label="Status"
              source="accepted"
              choices={types}
              alwaysOn
            />)}
          <CustomTextInput label="Nazwa" source="internalName" alwaysOn/>
        </Filter>
      }
      bulkActionButtons={false}
      title={`Punkty odbioru`}
    >
      <CustomDataGrid>
        <TextField source="name" label="Nazwa"/>
        <TextField source="city" label="Miejscowość"/>
        <TextField
          source="postCode"
          label="Kod pocztowy"
          sortable={false}
        />
        <TextField source="street" label="Ulica"/>
        <TextField source="voivodeshipName" label="Województwo"/>
        <TextField source="payerName" label="Płatnik"/>

        {PermissionHandler(props.permissions, "PickupPoints", "Read") && (
          <CustomActionsButtons
            label="Akcje"
            isEditAvailable={PermissionHandler(
              props.permissions,
              "PickupPoints",
              "Update"
            )}
            isDeleteAvailable={PermissionHandler(
              props.permissions,
              "PickupPoints",
              "Delete"
            )}
            isDescriptionAvailable={PermissionHandler(
              props.permissions,
              "PickupPoints",
              "Read"
            )}
          />
        )}

      </CustomDataGrid>
    </List>
  );
};
