import * as React from "react";
import {AutocompleteInput, BooleanInput, Edit, ReferenceInput, required, SimpleForm,} from "react-admin";
import {CustomTextInput,} from "../CustomInputs/CustomInputs";


import {PostBottomToolbarEdit} from "../CustomElements/PostBottomToolbars";
import PermissionHandler from "../../config/PermissionHandler";

const requiredField = required("Pole wymagane");

const CustomTitle = (props) =>
  `Płatnik użytkownik ${props.record.name} ${props.record.surname}`;

export default (props) => {
  return (
    <Edit {...props} actions={null} title={<CustomTitle />}>
      <SimpleForm
        toolbar={
          <PostBottomToolbarEdit
            isDeleteAvailable={PermissionHandler(
              props.permissions,
              "PayerUsers",
              "Delete"
            )}
          />
        }
      >
        <div className="d-flex w-100 justify-content-around">
          <div className="col-xs-6">
            <div className="table">
              <table className="table table-striped" style={{ width: "600px" }}>
                <tr>
                  <th style={{ width: "30%" }}>Imię</th>
                  <th>
                    <CustomTextInput source="name" validate={requiredField} />
                  </th>
                </tr>
                <tr>
                  <th>Nazwisko</th>
                  <th>
                    <CustomTextInput
                      source="surname"
                      validate={requiredField}
                    />
                  </th>
                </tr>

                <tr>
                  <th>E-mail</th>
                  <th>
                    <CustomTextInput source="email" validate={requiredField} />
                  </th>
                </tr>

                <tr>
                  <th>Numer Telefonu</th>
                  <th>
                    <CustomTextInput source="phone" validate={requiredField} />
                  </th>
                </tr>
                <tr>
                  <th>Płatnik</th>
                  <th>
                    <ReferenceInput
                      source="payerNetwork"
                      reference="payer_networks"
                      validate={requiredField}
                      filterToQuery={(searchText) => ({name: searchText})}
                      className="autocompleteinput"
                      label=""
                      fullWidth
                    >
                      <AutocompleteInput />
                    </ReferenceInput>
                  </th>
                </tr>
                <tr>
                  <th>Status</th>
                  <th>
                    <BooleanInput source="status" label=" " />
                  </th>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </SimpleForm>
    </Edit>
  );
};
